import { useEffect, useState } from 'react';

export default function useRefDimensions(ref) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function updateDimensions() {
      setDimensions(getDimensionsFromRef(ref));
    }

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [ref]);

  return dimensions;
}

function getDimensionsFromRef(ref) {
  const { width, height } = ref.current.getBoundingClientRect();

  return { width, height };
}
