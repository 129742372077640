import React from 'react';
import './Drawer.css';
import { Link } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import { toggleDrawer } from '../../ducks/reducer'
import { connect } from 'react-redux';

import compose from 'recompose/compose';

// Menu Icon
import MenuIcon from '@material-ui/icons/MenuTwoTone'

// Drawer Icons
import HomeIcon from '@material-ui/icons/HomeTwoTone';
// import SettingsIcon from '@material-ui/icons/SettingsTwoTone';
import DashboardIcon from '@material-ui/icons/DashboardTwoTone';
// import CalendarIcon from '@material-ui/icons/CalendarTodayTwoTone';
import CalendarIcon2 from '@material-ui/icons/InsertInvitationTwoTone';
// import MessageIcon from '@material-ui/icons/MessageTwoTone';
import CommentIcon from '@material-ui/icons/CommentTwoTone';
// import LiveTVIcon from '@material-ui/icons/LiveTvTwoTone'
import CommunityIcon from '@material-ui/icons/PersonPinTwoTone'
import AssesmentIcon from '@material-ui/icons/AssessmentTwoTone'
// import DataMarketplaceIcon from '@material-ui/icons/AccountBalanceTwoTone'
import DataMarketplaceIcon2 from '@material-ui/icons/StoreMallDirectoryTwoTone'
import GameficationIcon from '@material-ui/icons/GamepadTwoTone'
// import GameficationIcon2 from '@material-ui/icons/VideogameAssetTwoTone'
import MapIcon from '@material-ui/icons/MapTwoTone'

const styles = theme => ({
  icon: {
		fontSize: 28,
		color: 'white',
		marginRight: 35,
		cursor: 'pointer',
		opacity: 0.3,
	},
	iconActive: {
		fontSize: 28,
		color: 'white',
		marginRight: 35,
		cursor: 'pointer',
		opacity: 0.6,
	},
	menuCloseIcon: {
		color: 'var(--primary)',
		cursor: 'pointer',
	}
});

class Drawer extends React.Component {
	state = {
		activeTab: 'dashboard'
	};

	handleTabClick = (activeTab) => {
		this.setState({
			activeTab: activeTab
		})
	}

	handleDrawerClick = () => [
		this.props.toggleDrawer
	]
	
	render() {
		const { classes, isDrawerOpen, toggleDrawer } = this.props;
		return (
			<div className='drawer'>
				<div id='icon-bg' />
				<div className={isDrawerOpen ? 'drawer-opened' : 'drawer-closed'}>
					<div className='drawer-open-drawer' >
						<MenuIcon 
							className={classes.icon} 
							onClick={() => toggleDrawer(isDrawerOpen)}
							/>
						<div id='drawer-close-menu' >
							Menu
							<CloseIcon 
							className={classes.menuCloseIcon}
							onClick={() => toggleDrawer(isDrawerOpen)}
							/>
						</div>
					</div>
					<div id='divider' />
					{/* <Link to='/browse-events' onClick={(e) => this.handleTabClick('home')}>
						<div className='drawer-items' id={this.state.activeTab === 'home' ? 'active' : ''}>
							<HomeIcon className={this.state.activeTab === 'home' ? classes.iconActive: classes.icon} />
							Home
						</div>
					</Link> */}
					{/* <Link to='/publisher' onClick={(e) => this.handleTabClick('publisher')}>
						<div className='drawer-items' id={this.state.activeTab === 'publisher' ? 'active' : ''}>
								<CommentIcon className={this.state.activeTab === 'publisher' ? classes.iconActive: classes.icon} />
								Publisher
						</div>
					</Link> */}
					<Link to='/dashboard' onClick={(e) => this.handleTabClick('dashboard')}>
						<div className='drawer-items' id={this.state.activeTab === 'dashboard' ? 'active' : ''}>
							<HomeIcon className={this.state.activeTab === 'dashboard' ? classes.iconActive: classes.icon}/>
							Dashboard
						</div>
					</Link>
					<Link to='/analytics' onClick={(e) => this.handleTabClick('analytics')}>
						<div className='drawer-items' id={this.state.activeTab === 'analytics' ? 'active' : ''}>
							<AssesmentIcon className={this.state.activeTab === 'analytics' ? classes.iconActive: classes.icon} />
							Analytics
						</div>
					</Link>
					<Link to='/heatmap' onClick={(e) => this.handleTabClick('map')}>
						<div className='drawer-items' id={this.state.activeTab === 'map' ? 'active' : ''}>
							<MapIcon className={this.state.activeTab === 'map' ? classes.iconActive: classes.icon} />
							Map
						</div>
					</Link>
					<Link to='/data-marketplace' onClick={(e) => this.handleTabClick('datamarketplace')}>
						<div className='drawer-items' id={this.state.activeTab === 'datamarketplace' ? 'active' : ''}>
							<DataMarketplaceIcon2 className={this.state.activeTab === 'datamarketplace' ? classes.iconActive: classes.icon} />
							Data Marketplace
						</div>
					</Link>
					{/* <Link to='/community' onClick={(e) => this.handleTabClick('community')}>
						<div className='drawer-items' id={this.state.activeTab === 'community' ? 'active' : ''}>
							<CommunityIcon className={this.state.activeTab === 'community' ? classes.iconActive: classes.icon} />
							Community
						</div>
					</Link> */}
					{/* <Link to='/gameification' onClick={(e) => this.handleTabClick('gameification')}>
						<div className='drawer-items' id={this.state.activeTab === 'gameification' ? 'active' : ''}>
							<GameficationIcon className={this.state.activeTab === 'gameification' ? classes.iconActive: classes.icon} />
							Gameification
						</div>
					</Link> */}
					{/* <Link to='/calendar' onClick={(e) => this.handleTabClick('calendar')}>
						<div className='drawer-items' id={this.state.activeTab === 'calendar' ? 'active' : ''}>
							<CalendarIcon2 className={this.state.activeTab === 'calendar' ? classes.iconActive: classes.icon} />
							Calendar
						</div>
					</Link> */}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
  return {
    isDrawerOpen: state.isDrawerOpen,
  }
}

export default compose(connect(mapStateToProps,{toggleDrawer}),withStyles(styles))(Drawer)