import React from 'react';
import EkgChart from './EkgChart';
import useEkgChartData from './use-ekg-chart-data';

export default ({ eventId, xAxisMinutes, xAxisIncrement, reverseXAxis }) => {
  const { sentiment, sentimentHistory, prompts } = useEkgChartData({ eventId });
  const callouts = sentimentHistory
    .filter(removeZeroSentiment)
    // .filter(includeOnlyPeaksAndValleys)
    .concat(prompts);

  return <EkgChart sentiment={sentiment} data={sentimentHistory} callouts={callouts} xAxisMinutes={xAxisMinutes} xAxisIncrement={xAxisIncrement} reverseXAxis={reverseXAxis} />;
};

function removeZeroSentiment({ avgSentiment }) {
  return avgSentiment !== 0;
}

function includeOnlyPeaksAndValleys({ avgSentiment }, i, arr) {
  const { avgSentiment: prevSentiment } = arr[i - 1] || {};
  const { avgSentiment: nextSentiment } = arr[i + 1] || {};
  const isFirst = typeof prevSentiment === 'undefined';
  const isLast = typeof nextSentiment === 'undefined';

  if (isFirst || isLast) {
    return true;
  } else {
    const prevRising = avgSentiment > prevSentiment;
    const nextRising = avgSentiment < nextSentiment;
    const isChangingDirection = prevRising !== nextRising;

    return isChangingDirection;
  }
}
