import React from 'react'
import './IndividualFeed.css'
import numeral from 'numeral'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'var(--primary)',
    width: 60,
    height: 60,
    marginRight: 10,
  },
})

class IndividualFeed extends React.Component {
  constructor(){
    super()
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
  }
    
  handleClick(){
    this.setState(state => ({
      expanded: !state.expanded
    }))
  }

  
  
  render() {
    // let feed = this.props.feed
    let feed = this.props.feed
    const {classes} = this.props

    let split = feed.title.split('')
    let feedFirstLetter = split[0]
    // console.log('feed', feed)
    return(
      <div>
        <div className='feed-container'>
          <div 
            className='feed-container-expandable'
            onClick={this.handleClick}
            >
            <Avatar aria-label="Recipe" className={classes.avatar}>
                {feedFirstLetter}
            </Avatar>
            <div className='feed-title-desc-container'>
              <h3>{feed.title}</h3>
              <h4 className='description-text'>{feed.description}</h4>
            </div>
            
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        <div className={this.state.expanded ? 'feed-hidden feed-hidden-expanded': 'feed-hidden'}>
          <div className='dashboard-feed-info'>
            <p id='dashboard-feed-labels'>Followers</p>
            <p id='dashboard-feed-numbers'>
              {feed.usercount > 1000 ? numeral(feed.usercount).format('0.0a') : feed.usercount}
              </p>
          </div>
          <div className='dashboard-feed-info'>
            <p id='dashboard-feed-labels'>Posts</p>
            <p id='dashboard-feed-numbers'>
            {feed.promptcount> 1000 ? numeral(feed.promptcount).format('0.0a') : feed.promptcount}
            </p>
          </div>
          <div className='dashboard-feed-info'>
            <p id='dashboard-feed-labels'>votes</p>
            <p id='dashboard-feed-numbers'>
            {feed.responsecount > 1000 ? numeral(feed.responsecount).format('0.0a') : feed.responsecount}            </p>
          </div>
        </div>
      </div>
    )
  }
}

IndividualFeed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IndividualFeed)