import React from 'react'
import IndividualPost from './IndividualPost/IndividualPost';
// import axios from 'axios'
import TopPostsData from '../../data/TopPostsData.json'

import Firebase from '../../components/Firebase'
import Loading from '../../components/Loading/Loading';
const firebase = new Firebase()

class TopPosts extends React.Component {
  state = {
    topPosts: []
  }

  async componentDidMount(){
		let getTopFeeds =  firebase.functions.httpsCallable('dashboardGetTopPosts')
		let results = await getTopFeeds()
		// console.log(results.data)
		this.setState({
			topPosts: results.data
		})
	}

  render() {
    let data = this.state.topPosts
    // let data = TopPostsData
    return(
      <div className='third'>
        <div className='widget-header'>
          <h2>Top Engagement Posts</h2>
          <p>See all Posts</p>
        </div>
          {/* {data[0] ? 
            data[0].map( post => {
            return (
              <IndividualPost post={post} key={post.title}/>
            )
          }) : 
          <div>Loading</div>
        } */}
          {data[0] ? 
            data.map( post => {
            return (
              <IndividualPost post={post} key={post.title}/>
            )
          }) : 
          <div style={{height:'590px'}}>
            <Loading/>
          </div>
        }
        </div>
    )
  }
}

export default TopPosts