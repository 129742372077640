import React from 'react'
import './BrowseEvents.css'

// COMPONENTS
import SliderV2 from '../../components/SliderV2/SliderV2'
import PopularEvents from '../../components/PopularEvents/PopularEvents'
import RecommendedEvents from '../../components/RecommendedEvents/RecommendedEvents';

// FAKE DATA
import eventData from '../../data/events.json'
import recommendedEventsData from '../../data/recommendedEventsData.json'

// MATERIAL UI
import Divider from '@material-ui/core/Divider';

class BrowseEvents extends React.Component {

  render(){
    return(
      <div className='browse__events'>

        <div className='featured-events-wrapper'>
          <h2 className='featured-events-title'>Featured Events</h2>
          <SliderV2/>
        </div>

        <div className='secondary__events-wrapper'>
        <div className='popular__events'>
          <h2 className='secondary__events-title'>Popular Events</h2>
          <div className='popular__events-row'>
            {eventData.map(event => {
              return <PopularEvents event={event}/>
            })}
          </div>
        </div>
        <Divider style={{width:'100%', margin:'30px 20px',}}/>
        <div className='recommended__events'>
          <h2 className='secondary__events-title'>Recommended Events</h2>
          <div className='recommended__events-row'>
            {recommendedEventsData.map(event => {
              return <RecommendedEvents event={event}/>
            })}
          </div>
        </div>
      </div>

    </div>
    )
  }
}

export default BrowseEvents