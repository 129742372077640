import React, { Component } from "react"
import { geoAlbers, geoPath, geoCentroid } from "d3-geo"
import * as d3 from 'd3'
import { feature } from "topojson-client"
import axios from 'axios';


// COMPONENTS
import ChoroplethToolTip from '../../components/ChoroplethToolTip/ChoroplethToolTip'

// FAKE STATE DATA
import allStateData from '../../data/stateTestData.1.json'

// STATES GEODATA
import statesGeoData from '../../data/d3_Maps_GeoData/us_states_and_counties.json'

// STATE CONVERSION CHARTS
import fipsToState from '../../data/d3_Maps_GeoData/codeConversions/fipsToState.json'

// STATE ZCTA GEODATA IMPORTS
import Alabama from '../../data/d3_Maps_GeoData/zcta/Alabama.topo.json'
import Alaska from '../../data/d3_Maps_GeoData/zcta/Alaska.topo.json'
import Arizona from '../../data/d3_Maps_GeoData/zcta/Arizona.topo.json'
import Arkansas from '../../data/d3_Maps_GeoData/zcta/Arkansas.topo.json'
import California from '../../data/d3_Maps_GeoData/zcta/California.topo.json'
import Colorado from '../../data/d3_Maps_GeoData/zcta/Colorado.topo.json'
import Connecticut from '../../data/d3_Maps_GeoData/zcta/Connecticut.topo.json'
import Delaware from '../../data/d3_Maps_GeoData/zcta/Delaware.topo.json'
import Florida from '../../data/d3_Maps_GeoData/zcta/Florida.topo.json'
import Georgia from '../../data/d3_Maps_GeoData/zcta/Georgia.topo.json'
import Hawaii from '../../data/d3_Maps_GeoData/zcta/Hawaii.topo.json'
import Idaho from '../../data/d3_Maps_GeoData/zcta/Idaho.topo.json'
import Illinois from '../../data/d3_Maps_GeoData/zcta/Illinois.topo.json'
import Indiana from '../../data/d3_Maps_GeoData/zcta/Indiana.topo.json'
import Iowa from '../../data/d3_Maps_GeoData/zcta/Iowa.topo.json'
import Kansas from '../../data/d3_Maps_GeoData/zcta/Kansas.topo.json'
import Kentucky from '../../data/d3_Maps_GeoData/zcta/Kentucky.topo.json'
import Louisiana from '../../data/d3_Maps_GeoData/zcta/Louisiana.topo.json'
import Maine from '../../data/d3_Maps_GeoData/zcta/Maine.topo.json'
import Maryland from '../../data/d3_Maps_GeoData/zcta/Maryland.topo.json'
import Massachusetts from '../../data/d3_Maps_GeoData/zcta/Massachusetts.topo.json'
import Michigan from '../../data/d3_Maps_GeoData/zcta/Michigan.topo.json'
import Minnesota from '../../data/d3_Maps_GeoData/zcta/Minnesota.topo.json'
import Mississippi from '../../data/d3_Maps_GeoData/zcta/Mississippi.topo.json'
import Missouri from '../../data/d3_Maps_GeoData/zcta/Missouri.topo.json'
import Montana from '../../data/d3_Maps_GeoData/zcta/Montana.topo.json'
import Nebraska from '../../data/d3_Maps_GeoData/zcta/Nebraska.topo.json'
import Nevada from '../../data/d3_Maps_GeoData/zcta/Nevada.topo.json'
import NewHampshire from '../../data/d3_Maps_GeoData/zcta/New_Hampshire.topo.json'
import NewJersey from '../../data/d3_Maps_GeoData/zcta/New_Jersey.topo.json'
import NewMexico from '../../data/d3_Maps_GeoData/zcta/New_Mexico.topo.json'
import NewYork from '../../data/d3_Maps_GeoData/zcta/New_York.topo.json'
import NorthCarolina from '../../data/d3_Maps_GeoData/zcta/North_Carolina.topo.json'
import NorthDakota from '../../data/d3_Maps_GeoData/zcta/North_Dakota.topo.json'
import Ohio from '../../data/d3_Maps_GeoData/zcta/Ohio.topo.json'
import Oklahoma from '../../data/d3_Maps_GeoData/zcta/Oklahoma.topo.json'
import Oregon from '../../data/d3_Maps_GeoData/zcta/Oregon.topo.json'
import Pennsylvania from '../../data/d3_Maps_GeoData/zcta/Pennsylvania.topo.json'
import RhodeIsland from '../../data/d3_Maps_GeoData/zcta/Rhode_Island.topo.json'
import SouthCarolina from '../../data/d3_Maps_GeoData/zcta/South_Carolina.topo.json'
import SouthDakota from '../../data/d3_Maps_GeoData/zcta/South_Dakota.topo.json'
import Tennessee from '../../data/d3_Maps_GeoData/zcta/Tennessee.topo.json'
import Texas from '../../data/d3_Maps_GeoData/zcta/Texas.topo.json'
import Utah from '../../data/d3_Maps_GeoData/zcta/Utah.topo.json'
import Vermont from '../../data/d3_Maps_GeoData/zcta/Vermont.topo.json'
import Virginia from '../../data/d3_Maps_GeoData/zcta/Virginia.topo.json'
import Washington from '../../data/d3_Maps_GeoData/zcta/Washington.topo.json'
import WestVirginia from '../../data/d3_Maps_GeoData/zcta/West_Virginia.topo.json'
import Wisconsin from '../../data/d3_Maps_GeoData/zcta/Wisconsin.topo.json'
import Wyoming from '../../data/d3_Maps_GeoData/zcta/Wyoming.topo.json'

// FIREBASE
import Firebase from '../../components/Firebase'
const firebase = new Firebase()

// STATE DESCRIPTORS
const AL = 'Alabama' 
const AK = 'Alaska' 
const AZ = 'Arizona' 
const AR = 'Arkansas' 
const CA = 'California' 
const CO = 'Colorado' 
const CT = 'Connecticut' 
const DE = 'Delaware' 
const FL = 'Florida' 
const GA = 'Georgia' 
const HI = 'Hawaii' 
const ID = 'Idaho' 
const IL = 'Illinois' 
const IN = 'Indiana' 
const IA = 'Iowa' 
const KS = 'Kansas' 
const KY = 'Kentucky' 
const LA = 'Louisiana' 
const ME = 'Maine' 
const MD = 'Maryland' 
const MA = 'Massachusetts' 
const MI = 'Michigan' 
const MN = 'Minnesota' 
const MS = 'Mississippi' 
const MO = 'Missouri' 
const MT = 'Montana' 
const NE = 'Nebraska' 
const NV = 'Nevada' 
const NH = 'New Hampshire' 
const NJ = 'New Jersey' 
const NM = 'New Mexico' 
const NY = 'New York' 
const NC = 'North Carolina' 
const ND = 'North Dakota' 
const OH = 'Ohio' 
const OK = 'Oklahoma' 
const OR = 'Oregon' 
const PA = 'Pennsylvania' 
const RI = 'Rhode Island' 
const SC = 'South Carolina' 
const SD = 'South Dakota' 
const TN = 'Tennessee' 
const TX = 'Texas' 
const UT = 'Utah' 
const VT = 'Vermont' 
const VA = 'Virginia' 
const WA = 'Washington' 
const WV = 'West Virginia' 
const WI = 'Wisconsin' 
const WY = 'Wyoming'


class StateMap extends Component {
  state = {
      stateGeoData: [],
      zipGeoData: [],
      centroidX: null,
      centroidY: null,
      width: 1000,
      height: 600,
      translate: [],
      scale: 1200,
      x: 0,
      y: 0,
      isHovering: false,
      hoverLocation: '',
      hoverData: '',
      stateData: [],
      zipData: []
    }

  async componentDidMount() {
    // LOAD US MAP
    let states = statesGeoData.objects.states
    // GET STATE & ZIP DATA
    let getStateData =  firebase.functions.httpsCallable('dashboardGetStateResponseCount')
    let stateData = await getStateData()
    let getZipData =  firebase.functions.httpsCallable('dashboardGetZipResponseCount')
    let zipData = await getZipData()
    
    // let stateData = await axios.get('/api/getStateResponseCount')
    // let zipData = await axios.get('/api/getZipResponseCount')
    // SET STATE
    this.setState({
      stateGeoData: feature(statesGeoData, states).features,
      translate: [ this.state.width / 2, this.state.height / 2],
      stateData: stateData.data,
      zipData: zipData.data
    })
    // ALLOW ZOOM & PAN
    let svg = d3.select(this.refs.zoom).selectAll('g')
      .call(d3.zoom()
        .on("zoom", () => {
            svg.attr("transform", d3.event.transform)
        })
    );
  }

  projection = () => {
    // SET MAP TYPE, ZOOM, and LOCATION WITHIN DIV
    // let centered = [ this.state.width / 2, this.state.height / 2]
    return geoAlbers()
    .scale(this.state.scale)
    .translate(this.state.translate)
  }
  
  findPolyCenter(d){
    // FIND THE CENTER OF EACH STATE
    return geoCentroid(d)
  }

  getStateName = (stateId) => {
    if(stateId <= 9){
      let formattedId = ("0" + stateId).slice(-2)
      let stateName = fipsToState.filter(state => {
        return state.id === formattedId
      })
      return stateName[0]
    }else {
      let stateName = fipsToState.filter(state => {
        return state.id === stateId
      })
      // console.log('staename',stateName[0])
      return stateName[0]
    }
  }

  stateSwitcher = (state) => {
    switch (state){
      case AL:
        return(Alabama)
        
      case AK:
        return(Alaska)

      case AZ:
        return(Arizona)

      case AR:
        return(Arkansas)

      case CA:
        return(California)

      case CO:
        return(Colorado)

      case CT:
        return(Connecticut)

      case DE:
        return(Delaware)

      case FL:
        return(Florida)

      case GA:
        return(Georgia)

      case HI:
        return(Hawaii)

      case ID:
        return(Idaho)

      case IL:
        return(Illinois)

      case IN:
        return(Indiana)

      case IA:
        return(Iowa)

      case KS:
        return(Kansas)

      case KY:
        return(Kentucky)

      case LA:
        return(Louisiana)

      case ME:
        return(Maine)

      case MD:
        return(Maryland)

      case MA:
        return(Massachusetts)

      case MI:
        return(Michigan)

      case MN:
        return(Minnesota)

      case MS:
        return(Mississippi)

      case MO:
        return(Missouri)

      case MT:
        return(Montana)

      case NE:
        return(Nebraska)

      case NV:
        return(Nevada)

      case NH:
        return(NewHampshire)

      case NJ:
        return(NewJersey)

      case NM:
        return(NewMexico)

      case NY:
        return(NewYork)

      case NC:
        return(NorthCarolina)

      case ND:
        return(NorthDakota)

      case OH:
        return(Ohio)

      case OK:
        return(Oklahoma)

      case OR:
        return(Oregon)

      case PA:
        return(Pennsylvania)

      case RI:
        return(RhodeIsland)

      case SC:
        return(SouthCarolina)

      case SD:
        return(SouthDakota)

      case TN:
        return(Tennessee)

      case TX:
        return(Texas)

      case UT:
        return(Utah)

      case VT:
        return(Vermont)

      case VA:
        return(Virginia)

      case WA:
        return(Washington)

      case WV:
        return(WestVirginia)

      case WI:
        return(Wisconsin)

      case WY:
        return(Wyoming)

      default: return(null)
    }
  }

  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    })
  }

  toggleHover = (state) => {
    this.setState({
      isHovering: state
    })
  }
  
  getStateData = (stateAbbrev) => {
    let hoverData = this.state.stateData.filter(state => {
      return state.stateabbrev === stateAbbrev
    })
    if ( hoverData <= 0 ) { return 0
    } else { return hoverData[0].responsecount }
  }

  handleStateHover = (stateId, hoverState) => {
    let stateData = this.getStateName(stateId)
    let hoverData = this.getStateData(stateData.abbreviation)

    this.setState({
      isHovering: hoverState,
      hoverLocation: stateData.name,
      hoverData: hoverData
    })
    
  }

  handleStateClick = (stateId) => {
    let stateName, stateTopo, zip, formattedNameArr

    stateName = this.getStateName(stateId)
    stateTopo = this.stateSwitcher(stateName.name)
    formattedNameArr = stateName.name.split(' ')

    let formattedName = () => {
      if(formattedNameArr.length > 1){
        return `${formattedNameArr[0]}_${formattedNameArr[1]}`
      }else {
        return stateName.name
      }
    }
    zip = stateTopo.objects[`${formattedName()}.geo`]
    this.setState({
      zipGeoData: feature(stateTopo, zip).features,
      // translate,
      // scale: this.state.scale * scale
    })
  }

  handleZipHover = (zipIndex) => {
    let zipHover
    let hoverData = this.state.zipData[zipIndex] ? true : false
    if (hoverData){
      zipHover = this.state.zipData[zipIndex].responsecount
    }
    this.setState({
      isHovering: true,
      hoverLocation: this.state.zipGeoData[zipIndex].id,
      hoverData: zipHover
    })
  }

  colorMap = (stateId) => {
    let stateName = this.getStateName(stateId)
    // console.log(stateName)
    let stateAbbrev
    if (stateName){
      stateAbbrev = stateName.abbreviation
    }
    let color 
    let onlyValues = this.state.stateData.map(e => {
      return e.responsecount
    })
    let minValue = Math.min.apply(null, onlyValues),
      maxValue = Math.max.apply(null, onlyValues);
    // create color palette function
    // color can be whatever you wish
    let paletteScale = d3.scaleLog()
    .domain([minValue, maxValue])
    .range(["#EFEFFF", "#02386F"]); // blue color
          
    // fill dataset in appropriate format
    this.state.stateData.forEach(function (item) { 
      if (item.stateabbrev === stateAbbrev){
        color = paletteScale(item.responsecount)
      }
    }) 
    return color
  }

  // zipColorMap = (d) => {
  //   console.log(d)
  //   let color 
  //   let onlyValues = this.state.zipData.map(e => {
  //     return e.responsecount
  //   })
  //   // console.log(onlyValues)
  //   let minValue = Math.min.apply(null, onlyValues),
  //     maxValue = Math.max.apply(null, onlyValues);
  //   // // create color palette function
  //   // // color can be whatever you wish
  //   let paletteScale = d3.scaleLog()
  //   .domain([minValue, maxValue])
  //   .range(["#EFEFFF", "#02386F"]); // blue color
          
  //   // // fill dataset in appropriate format
  //   this.state.zipData.forEach(function (item) { 
  //     // console.log('zipitem', item)
  //     if(d === item.id){
  //       color = paletteScale(item.responsecount)
  //     }
  //   }) 
  //   return color
  // }

  render() {
    // console.log('stateData', this.state.stateData)
    // console.log('zipData', this.state.zipData)
    return (
      <div>
        { this.state.isHovering ? 
        <ChoroplethToolTip 
          top={this.state.y -115} 
          left={this.state.x -25} 
          hoverLocation={this.state.hoverLocation}
          hoverData={this.state.hoverData} /> 
        : null }
      <svg 
        // width={this.state.width} 
        // height={this.state.height} 
        ref='zoom' 
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}
        >
        <g className="us__map" >
          {
            this.state.stateGeoData.map((d,i) => (
              <path
                key={ `path-${ i }` }
                d={ geoPath().projection(this.projection())(d) }
                className="county"
                fill={this.colorMap(d.id.toString())}
                stroke="#FFFFFF"
                strokeWidth={ 0.5 }
                onMouseMove={this.handleMouseMove}
                onMouseLeave={() => this.toggleHover(false)}
                onMouseOver={ () => this.handleStateHover(d.id.toString(),true)}
                onClick={ () => this.handleStateClick(d.id.toString()) }
                cursor='pointer'
              />
            ))
          }
        </g>
        <g className="state" >
          {
            this.state.zipGeoData.map((d,i) => (
              <path
                key={ `path-${ i }` }
                d={ geoPath().projection(this.projection())(d) }
                className="county"
                fill={ `rgba(38,50,56,${ 1 / this.state.zipGeoData.length * i})` }
                onMouseMove={this.handleMouseMove}
                onMouseLeave={() => this.toggleHover(false)}
                onMouseOver={ () => this.handleZipHover(i) }
                cursor='pointer'
              />
            ))
          }
        </g>
      </svg>
      </div>
    )
  }
}

export default StateMap