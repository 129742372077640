import React from 'react'

import './PopularEvents.scss'

import team2Logo from '../../assets/images/Dallas-Cowboys-Logo.png'

class SingleEvent extends React.Component{
  state={}

  render(){
    let team1 = {
      name:"team 1",
      logo: team2Logo,
      city: "City 1"
    }
    let team2 = {
      name:"team 2",
      logo:team2Logo,
      city:"city 2"
    }
    return(
      <div className="single__event">
        <div className='single__event-single_team'>
          <img src={team1.logo} alt='' className='single__event-img'/>
          <div className='single__event-identity left'>
            <p>{team1.city}</p>
            <p>{team1.name}</p>
          </div>
        </div>
        <p>vs</p>
        <div className='single__event-single_team'>
          <div className='single__event-identity right'>
            <p>{team2.city}</p>
            <p>{team2.name}</p>
          </div>
          <img src={team2.logo} alt='' className='single__event-img'/>
        </div>
      </div>
    )
  }
}

export default SingleEvent