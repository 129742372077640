import React from 'react'

import { ResponsiveLine } from '@nivo/line'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Global from '../../data/BWWChart3-Global.json'
import Huddle from '../../data/BWWChart3-Huddle.json'

const title = {
  title: 'Intensity Over Time'
}
class AnalyticsChart3 extends React.Component{
  state={
    chart: Global,
    name: "Global",
    chartData: []
  };

  handleChange = event => {
    this.setState(
      { chart: event.target.value,
        name: event.target.value === Global ? "Global" : "Huddle"
      }
    );
  };

  render(){

    let data = [];
    this.state.chart.forEach(function (i) {
      data.push({x: i.timestamp, y: i.avgSentiment, segment: i.segment})
    });

    this.state.chartData = [{
      dataset: "1",
      id: this.state.name,
      data: data
    }]

    // console.log(this.state.chartData);

    return(
      <div className='vote-graph two-thirds  primary-border'>
      <div className='vote-graph-top-bar'>
        <div>
          <div>
            <h1 className='highlight-primary'>{title.title}</h1>
          </div>
        </div>
        <div className=''>
          <form style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.chart}
                onChange={this.handleChange}
                displayEmpty
                name="dataType"
              >
                <MenuItem value={Global}>Global</MenuItem>
                <MenuItem value={Huddle}>Huddle</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        </div>
        <div className='chart'>
         <ResponsiveLine
            data={this.state.chartData}
            curve='linear'
            margin={{
                "top": 70,
                "right": 20,
                "bottom": 60,
                "left": 50
            }}
            enableGridX={false}
            axisTop={null}
            axisRight={null}
            xScale={{
              'type': 'time',
              'format': "%m/%d/%Y %H:%M:%S %p",
              'precision': "minute"
             }}
             yScale={{
               'type': 'linear',
               'min': 0,
               'max': 100
             }}
             tooltip={({ id, value}) => (
                <strong>
                  {id}: {value}
                </strong>
              )}
             axisBottom={{
              'format': "%H:%M %p",
              'tickValues': 'every 15 minutes',
              'orient' : 'bottom',
              'tickSize' : 5,
              'tickPadding' : 5,
              'tickRotation' : 0,
              'legend' : 'Real Time',
              'legendOffset' : 46,
              'legendPosition' : 'middle'
            }}
            axisLeft={{
              'orient' : 'left',
              'tickPadding' : 5,
              'tickRotation' : 0,
              // 'legend' : 'Event Time',
              // 'legendOffset' : -40,
              // 'legendRotation' : 90,
              // 'legendPosition' : 'middle'
            }}
            colors={['var(--primary']}
            enableDots={false}
            enableDotLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[
                {
                    "anchor": "top-right",
                    "direction": "row",
                    "justify": false,
                    "translateX": 10,
                    "translateY": -50,
                    "itemsSpacing": 0,
                    "itemDirection": "left-to-right",
                    "itemWidth": 80,
                    "itemHeight": 20,
                    "itemOpacity": 0.75,
                    "symbolSize": 20,
                    "symbolShape": "circle",
                    "symbolBorderColor": 'rgba(0, 0, 0, .5)',
                    "effects": [
                        {
                            "on": "hover",
                            "style": {
                                "itemBackground": "rgba(0, 0, 0, .03)",
                                "itemOpacity": 1
                            }
                        }
                    ]
                }
            ]}
            theme= {{
                'tooltip': {
                   'container': {
                     'color': 'white',
                     'background': 'rebeccapurple',
                   }
                 },
                'grid': {
                    'line' : {
                        'stroke': '#EEF5F5',
                        'strokeWidth': '1px'
                    }
                },
                'axis': {
                    'ticks': {
                        'text': {
                            'fill': 'var(--chart-axis-label)',
                            'fontSize': 18,
                            'fontWeight': '500',
                            'fontFamily': 'Source Sans Pro'
                        }
                    },
                    'legend': {
                      'text': {
                        'fontSize': 14
                      }
                    }
                },
                'tooltipFormat': {
                    'container': {
                        'background': 'red'
                    }
                }
            }}
        />
      </div>
      </div>
    )
  }
}

export default AnalyticsChart3
