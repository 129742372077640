import React from 'react'
import IndividualFeed from './IndividualFeed/IndividualFeed';

import Loading from '../../components/Loading/Loading';

import Firebase from '../../components/Firebase'
const firebase = new Firebase()

class Feeds extends React.Component {
  state= {
    feedCount: '',
    feeds: [],
  }

  async componentDidMount(){    
		let getTopFeeds =  firebase.functions.httpsCallable('dashboardGetTopFeeds')
		let getFeedCount =  firebase.functions.httpsCallable('dashboardCountFeeds')
    let topFeedResults = await getTopFeeds()
    let feedCountResults = await getFeedCount()

		this.setState({
			feeds: topFeedResults.data,
			feedCount: feedCountResults.data[0].feedcount
		})
	}
  
  render() {
    let feeds = this.state.feeds
    return(
      <div className='third'>
        <div className='widget-header'>
          <h2>Top Performing Feeds <span>{this.state.feedCount ? `(${this.state.feedCount})` : '(...)'}</span></h2>
          {/* <span>Feeds <span>(28)</span></span> */}
          <p>See all Feeds</p>
        </div>
        {feeds[0] ?
            <div className='primary-border'>
              {feeds.map( feed => {
                return(
                <IndividualFeed feed={feed} key={feed.title}/>
                )
              })}
        </div>
              : 
              <div style={{height:'590px'}}>
                <Loading/>
              </div>
            }
      </div>
    )
  }
}

export default Feeds