import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import PostsVsResponses_Data01 from '../data/PostsVsResponses_Data01.json'

class VotesVsPosts extends React.Component {
  render(){
    //   console.log('chart', this.props.chart.value.toString())
    return(
         <ResponsiveLine
        data={this.props.chart}
        curve='natural'
        margin={{
            "top": 70,
            "right": 20,
            "bottom": 40,
            "left": 30
        }}
        enableGridX={false}
        axisTop={null}
        axisRight={null}
        axisBottom={this.props.chart === PostsVsResponses_Data01 ? null :
            {
            "orient": "bottom",
            "tickSize": 0,
            "tickPadding": 15,
            "tickRotation": 0,
            }}
        axisLeft={null}
        colors={['#0070AC','#00B1FF']}
        enableDots={false}
        // dotSize={14}
        // dotColor={"#fff"}
        // dotBorderWidth={2}
        // dotBorderColor={{
        //     "from": "color",
        //     "modifiers": []
        // }}
        enableDotLabel={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        crosshairType={'x'}
        legends={[
            {
                "anchor": "top-right",
                "direction": "row",
                "justify": false,
                "translateX": 10,
                "translateY": -50,
                "itemsSpacing": 0,
                "itemDirection": "left-to-right",
                "itemWidth": 80,
                "itemHeight": 20,
                "itemOpacity": 0.75,
                "symbolSize": 20,
                "symbolShape": "circle",
                'symbolBorderColor': 'rgba(0, 0, 0, .5)',
                "effects": [
                    {
                        "on": "hover",
                        "style": {
                            "itemBackground": "rgba(0, 0, 0, .03)",
                            "itemOpacity": 1
                        }
                    }
                ]
            }
        ]}
        theme= {{
            'grid': {
                'line' : {
                    'stroke': '#EEF5F5',
                    'strokeWidth': '1px'
                }
            },
            'axis': {
                'ticks': {
                    'text': {
                        'fill': '#BDD5D7',
                        'fontSize': 18,
                        'fontWeight': '500',
                        'fontFamily': 'Source Sans Pro'
                    }
                }
            },
            'tooltipFormat': {
                'container': {
                    'background': 'red'
                }
            }
        }}
    />
    )
  }
}

export default VotesVsPosts
