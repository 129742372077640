import React from 'react';
import './AppBar.css';
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import opineLogo from '../../assets/images/OpineLogoMark.svg'

import UserAppBarRight from './UserAppBarRight/UserAppBarRight';

import { AuthUserContext } from '../Session';

class AppBar extends React.Component {
	render() {
		return (
			<div className= 'app-bar'>
        <Link to={'/dashboard'}>
          <div className='app-bar-left'>
          <img src={opineLogo} alt="Opine, Inc" id='app-bar-logo'/>
          <h1>Dashboard</h1>
        </div></Link>
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? 
            <UserAppBarRight /> 
            : <Link to={'/login'}><Button>Sign In</Button></Link>
          }
        </AuthUserContext.Consumer>
      </div>
		);
	}
}

function mapStateToProps(state) {
  return {
    authUser: state.authUser
  }
}

export default connect(mapStateToProps)(AppBar);