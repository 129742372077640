import React from 'react'

function MagnifyingGlass(props) {
      return (
        <svg className="notificationIcons" xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23">
  <g id="Notification" transform="translate(-1395 -20)" aria-labelledby="Notifications">
    <path id="Notification_Icon" data-name="Notification Icon" d="M-1371,81h4a2.006,2.006,0,0,1-2,2A2.006,2.006,0,0,1-1371,81Zm-5-1a.945.945,0,0,1-1-1,.945.945,0,0,1,1-1h.5a4.354,4.354,0,0,0,1.5-3V72a4.952,4.952,0,0,1,5-5,4.951,4.951,0,0,1,5,5v3a4.351,4.351,0,0,0,1.5,3h.5a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1Z" transform="translate(2772 -40)" />
    <g id="Notification_Badge" data-name="Notification Badge" transform="translate(1406 22)" fill="#ffc06a" stroke="#fff" strokeWidth="2">
      <circle cx="4" cy="4" r="4" stroke="none"/>
      <circle cx="4" cy="4" r="5" fill="none"/>
    </g>
  </g>
</svg>
      )
    }
   
   export default MagnifyingGlass