import React from 'react'

import { ResponsiveLine } from '@nivo/line'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BWWChart1Global from '../../data/BWWChart1-Global.json'
import BWWChart1Huddle from '../../data/BWWChart1-Huddle.json'
import WidgetHeaderHOC from '../../components/WidgetHeaderHOC/WidgetHeaderHOC.js';

const title ={
  title:'$ Per Ticket vs Promotions Offered'
}

class AnalyticsChart1 extends React.Component{
  state={
    chart: [],
    dataset: "Yesterday",
    chartData: BWWChart1Global
  };

  handleChange = event => {
    this.setState(
      {dataset: event.target.value}
    );
  };

  render(){
    let datasetVale = this.state.dataset, min=9999, max = 0;

    this.state.chart = this.state.chartData.filter(function (el) {
       return el.Category === datasetVale;
      });

    let keysHash = [];
    this.state.chart.forEach(function (i) {
      keysHash.push({x: i.NumPromotions, y: i.DollarsPerTicket})
      if (i.DollarsPerTicket<min){min=i.DollarsPerTicket}
      if (i.DollarsPerTicket>max){max=i.DollarsPerTicket}
    });
    // console.log("min",min);
    // console.log("max",max);

    this.state.chart = [{
      dataset: "",
      id: "",
      data: keysHash
    }]
    // console.log(this.state);

    return(
      <div className='vote-graph  primary-border' style={{width: '100%', paddingBottom:'30px'}}>
      <div className='vote-graph-top-bar'>
          <form style={{width:'100%', display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.dataset}
                onChange={this.handleChange}
                displayEmpty
                name="dataType"
              >
              <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
              <MenuItem value={'LastWeek'}>Last Week</MenuItem>
              <MenuItem value={'LastMonth'}>Last Month</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        <div className='chart'>
         <ResponsiveLine
            data={this.state.chart}
            keys={[ 'DollarsPerTicket']}
            indexBy="NumPromotions"
            curve='natural'
            margin={{
                "top": 20,
                "right": 20,
                "bottom": 70,
                "left": 70
            }}
            yScale={{
              'type': 'linear',
              'stacked': false,
              'min': min,
              'max': max
            }}
            enableGridX={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                'orient': 'bottom',
                'tickSize': 5,
                'tickPadding': 5,
                'tickRotation': 0,
                'legend': '# of Promotions Offered',
                'legendOffset': 56,
                'legendPosition': 'middle'
            }}
            axisLeft={{
                'orient': 'left',
                'tickSize': 5,
                'tickPadding': 5,
                'tickRotation': 0,
                'legend': 'Avg $ per Ticket',
                'legendOffset': -60,
                'legendPosition': 'middle'
            }}
            colors={['var(--primary']}
            enableDots={false}
            enableDotLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme= {{
                'grid': {
                    'line' : {
                        'stroke': '#EEF5F5',
                        'strokeWidth': '1px'
                    }
                },
                'axis': {
                    'ticks': {
                        'text': {
                            'fill': 'var(--chart-axis-label)',
                            'fontSize': 18,
                            'fontWeight': '500',
                            'fontFamily': 'Source Sans Pro'
                        }
                    },
                    'legend': {
                      'text': {
                        'fontSize': 14
                      }
                    }
                },
                'tooltipFormat': {
                    'container': {
                        'background': 'red'
                    }
                }
            }}
        />
      </div>
      </div>
    )
  }
}

const WrappedComponent = WidgetHeaderHOC(AnalyticsChart1, title)

export default WrappedComponent
