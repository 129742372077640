import React from 'react'
import DataMarketplaceChart1 from '../../charts/Marketplace/DataMarketplaceChart1'

class DataMarketplace extends React.Component{
  render(){
    return(
      <div>
        <DataMarketplaceChart1 />
      </div>
    )
  }
}

export default DataMarketplace