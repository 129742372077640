import { useContext, useEffect, useMemo } from 'react';
import { EkgSentimentContext } from './ekg-sentiment-context';
import { EkgPromptsContext } from './ekg-prompts-context';

export default ({ eventId }) => {
  const { sentimentByEventId, sentimentHistoryByEventId, listen: listenToSentiment } = useContext(
    EkgSentimentContext
  );
  const { promptsByEventId, listen: listenToPrompts } = useContext(EkgPromptsContext);
  const sentiment = useMemo(() => {
    const sentiment = sentimentByEventId[eventId];

    return (
      sentiment && {
        ...sentiment,
        avgSentiment: sentiment.avgSentiment / 100,
        timestamp: sentiment.timestamp.seconds * 1000,
      }
    );
  }, [eventId, sentimentByEventId]);
  const sentimentHistory = useMemo(
    () =>
      (sentimentHistoryByEventId[eventId] || []).map(item => ({
        avgSentiment: item.avgSentiment / 100,
        timestamp: item.timestamp.seconds * 1000,
      })),
    [eventId, sentimentHistoryByEventId]
  );
  const prompts = useMemo(
    () =>
      (promptsByEventId[eventId] || []).map(item => ({
        text: item.text,
        timestamp: item.timestamp.seconds * 1000,
      })),
    [eventId, promptsByEventId]
  );

  useEffect(() => {
    let unlisten;

    (async () => (unlisten = await listenToSentiment(eventId)))();

    return () => unlisten && unlisten();
  }, [eventId, listenToSentiment]);

  useEffect(() => {
    let unlisten;

    (async () => (unlisten = await listenToPrompts(eventId)))();

    return () => unlisten && unlisten();
  }, [eventId, listenToPrompts]);

  return { prompts, sentiment, sentimentHistory };
};
