import React from 'react'
import DataMarketplaceCardHOC from '../../cards/DataMarketplaceCardHOC/DataMarketplaceCardHOC'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import D3StatesMap from '../D3Map/D3_States_Map'
import Plot from 'react-plotly.js';
import AnalyticsChartStates from '../../data/AnalyticsChartStates.json'

const data = {
  title: 'Opine Responses Across the United States',
  description: 'See how users are voting based on their location.',
  url: 'Opinelive.com'
}

class DataMarketplaceChart1 extends React.Component{
  state= {
    codes: ['Al'],
    exports: [12],
    states: ['Alabama'],
    date: 'all',
    event: 'all',
    feed: 'all',
    statement: 'all',

  }

  codes(data){
    var stateCodes = []
    data.map(e => {
      return stateCodes.push(e.code)
    })
    return stateCodes
  }

  totalResponses(data){
    var totalResponses = []
    data.map(e => {
      return totalResponses.push(e.event.event1.feed1.post1.week2.totalResponses)
    })
    return totalResponses
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  states(data){
    var states = []
    data.map(e => {
      return states.push(e.state)
    })
    return states
  }

  render(){
    return(
      <div >
      <div>
        <form autoComplete="off" style={{display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
            <FormControl variant="filled" style={{width: '75%', }}>
              <Select
                value={this.state.date}
                onChange={this.handleChange}
                displayEmpty
                name="date"
              >
                <MenuItem value={'all'}>All Dates</MenuItem>
                <MenuItem value={'thisWeek'}>This Week</MenuItem>
                <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                <MenuItem value={'lastTwoWeeks'}>Last Two Weeks</MenuItem>
                <MenuItem value={'lastThreeMonths'}>Last Three Weeks</MenuItem>
                <MenuItem value={'lastMonth'}>Last Month</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" style={{width: '75%', }}>
              <Select
                value={this.state.event}
                onChange={this.handleChange}
                displayEmpty
                name="event"
              >
                <MenuItem value={'all'}>All Events</MenuItem>
                <MenuItem value={'lakersKnicks'}>Lakers vs Knicks</MenuItem>
                <MenuItem value={'jazzWarriors'}>Jazz vs Warriors</MenuItem>
                <MenuItem value={'timbersRaptors'}>Timbers vs Raptors</MenuItem>
                <MenuItem value={'heatCavs'}>Heat vs Cavs</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" style={{width: '75%', }}>
              <Select
                value={this.state.feed}
                onChange={this.handleChange}
                displayEmpty
                name="feed"
              >
                <MenuItem value={'all'}>All Feeds</MenuItem>
                <MenuItem value={'offense'}>Only Offense</MenuItem>
                <MenuItem value={'defense'}>Just the Defense</MenuItem>
                <MenuItem value={'knicks'}>Knicks Only</MenuItem>
                <MenuItem value={'jazz'}>Nothing but Jazz</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" style={{width: '75%', }}>
              <Select
                value={this.state.statement}
                onChange={this.handleChange}
                displayEmpty
                name="statement"
              >
                <MenuItem value={'all'}>All Posts</MenuItem>
                <MenuItem value={'post1'}>If so and so did something...</MenuItem>
                <MenuItem value={'post2'}>If so and so did something...</MenuItem>
                <MenuItem value={'post3'}>If so and so did something...</MenuItem>
                <MenuItem value={'post4'}>If so and so did something...</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        <div style={{  width: '460px', height: '300px', marginTop:'20px'}}>
          <D3StatesMap/>
        {/* <Plot
            data={[
            {type: 'choropleth',
            locationmode: 'USA-states',
            locations: this.codes(AnalyticsChartStates),
            z: this.totalResponses(AnalyticsChartStates),
            text: this.states(AnalyticsChartStates),
            zmin: 0,
            zmax: 17000,
            colorscale: [
                [0, '#e6eaef'], [0.2, '#b3bfce'],
                [0.4, '#8095ae'], [0.6, '#667f9d'],
                [0.8, '#33557d'], [1, '#002a5c']
            ],
            showscale: false,
            marker: {
                line:{
                    color: 'rgb(255,255,255)',
                    width: 2
                }
            }
            }
            ]}
            layout={{
              dragmode: false,
              margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
              },
              geo:{
                scope: 'usa',
                showlakes: true,
                lakecolor: 'rgb(255,255,255)',
              },
            }}
            style={{width:'100%', height:'100%'}}
            displayModeBar = {false}
            // responsive = "true"
          /> */}
        </div>
      </div>
    )
  }
}

const WrappedComponent = DataMarketplaceCardHOC(DataMarketplaceChart1, data)
export default WrappedComponent