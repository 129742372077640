import React from 'react'

class CarouselIndicator extends React.Component {
  render() {
    return (
      <li>
        <div
          className={
            this.props.index === this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        />
      </li>
    );
  }
}

export default CarouselIndicator