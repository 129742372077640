import React from 'react'
import { ResponsiveCalendar } from '@nivo/calendar'
// import AnalyticsChart02Data from '../../data/AnalyticsChart02Data.json'
import CalendarData from '../../data/Calendar.json'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// const barChartData = 
 
const data = {
  title: 'Published Statements per Day',
  description: 'This chart shows how many statements were published each day.',
  url: 'Opinelive.com',
}

const legend = [
  {level:'0-10', color: '#eeeeee'},
  {level:'11-20', color: '#8095ae'},
  {level:'21-30', color: '#667f9d'},
  {level:'31-45', color: '#4d6a8d'},
  {level:'46+', color: '#002a5c'}
]

class StatementsCalendar extends React.Component{
  state={
    timeFrame: "2018-03-01",
    filter: 'Time'
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    return(
      <div className='third'>
        <div className='widget-header'>
					<h2>Publishing Trends </h2>
					<p>See More</p>
				</div>
        <div className='primary-border' style={{height:'400px', background:'white', paddingTop:'40px'}}>
        <div className='card-HOC-chart-container' >
          {/* <form style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.timeFrame}
                onChange={this.handleChange}
                displayEmpty
                name="timeFrame"
                className='{classes.selectEmpty}'
              >
                <MenuItem value={'2018-07-05'}>This Week</MenuItem>
                <MenuItem value={'2018-06-28'}>Last Week</MenuItem>
                <MenuItem value={'2018-06-21'}>Last Two Weeks</MenuItem>
                <MenuItem value={'2018-06-14'}>Last Three Weeks</MenuItem>
                <MenuItem value={'2018-06-05'}>Last Month</MenuItem>
                <MenuItem value={'2018-03-01'}>All Time</MenuItem>
              </Select>
            </FormControl>
          </form> */}
          <ResponsiveCalendar
            data={CalendarData}
            from={this.state.timeFrame}
            to="2019-05-27"
            emptyColor="#eeeeee"
            colors={['#eeeeee','#99aabe','#8095ae','#667f9d','#4d6a8d','#002a5c']}
            margin={{ top: 0, right: 40, bottom: 0, left: 40 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            // legends={[
            // {
            //   anchor: 'bottom-right',
            //   direction: 'row',
            //   translateY: 36,
            //   itemCount: 4,
            //   itemWidth: 42,
            //   itemHeight: 36,
            //   itemsSpacing: 14,
            //   itemDirection: 'right-to-left'
            // }
            // ]}
          />
          <div style={{display: 'flex', justifyContent:'flex-end', marginRight:'20px', position:'relative', bottom:'50px'}}>
            {legend.map(e => {
              return(
                <div style={{display:'flex', alignItems: 'center', marginRight: '15px'}}>
                <p style={{marginRight:'5px', fontSize: '13px', color:'#363636'}}>{e.level}</p>
                <div style={{width:'16px', height:'16px', background:`${e.color}`}}/>
              </div>
              )
            })}
          </div>
          </div>
          </div>
      </div>
    )
  }
}

export default StatementsCalendar