import React from 'react';
import OverviewWidget from '../../widgets/OverviewWidget/OverviewWidget';
import EkgChartWithData from '../../charts/EkgChart/EkgChartWithData';
import EkgSentimentContext from '../../charts/EkgChart/ekg-sentiment-context';
import EkgPromptsContext from '../../charts/EkgChart/ekg-prompts-context';
import Choropleth from '../../charts/Choropleth/Choropleth';
import Feeds from '../../widgets/Feeds/Feeds';
import TopPosts from '../../widgets/TopPosts/TopPosts';
import './Dashboard.css';
import PropTypes from 'prop-types';
import cowboysLogo from '../../assets/images/Dallas_Cowboys.png';
import VoteGraphWidget from '../../widgets/VoteGraphWidget/VoteGraphWidget'

import { AuthUserContext, withAuthorization } from '../../components/Session';
import StatementsCalendar from '../../charts/StatementsCalendar/StatementsCalendar';

class Dashboard extends React.Component {
  state = {
    logo: cowboysLogo,
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="dashboard">
            <div className="row">
              <OverviewWidget data={this.state} />
              <EkgSentimentContext>
                <EkgPromptsContext>
                  <EkgChartWithData eventId="kso2hd723ve39zfz4uq" xAxisMinutes="7" xAxisIncrement="1" reverseXAxis="1" />
                </EkgPromptsContext>
              </EkgSentimentContext>
            </div>
            <div className="row">
              <VoteGraphWidget/>
              <StatementsCalendar/>
            </div>
            <div className='row'>
              <Feeds />
              <Choropleth />
              <TopPosts />
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Dashboard);
