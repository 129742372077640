import React from 'react';

class Publisher extends React.Component {
	state = {
		activeTab: 'home'
  };
  
  render() {
		return (
      <div>Dashboard</div>
    )
  }
}

export default Publisher