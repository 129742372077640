import constants from '../../constants';

const ACCURACY = 100;

export default function renderEkgChart({ ctx, timeSeries, range }) {
  const { width, height } = ctx.canvas;

  ctx.clearRect(0, 0, width, height);

  renderAxes({ ctx, width, height });

  renderTimeSeries({ ctx, timeSeries, range, height, width });

  renderLeftMask({ ctx, height });

  const getCoordinates = getGetCoordinates({ timeSeries, range, height, width });

  return { getCoordinates };
}

function renderAxes({ ctx, width, height }) {
  ctx.strokeStyle = constants.CHART.COLORS.AXES;

  renderYAxis({ ctx, height });
  renderXAxis({ ctx, width, height });
}

function renderYAxis({ ctx, height }) {
  const xInset = constants.CHART.DIMENSIONS.X_AXIS_INSET;
  const yInset = constants.CHART.DIMENSIONS.Y_AXIS_INSET;
  const startX = xInset;
  const startY = yInset;
  const endX = startX;
  const endY = height - yInset;

  ctx.beginPath();

  ctx.lineWidth = constants.CHART.DIMENSIONS.Y_AXIS_LINE_WIDTH;

  ctx.moveTo(startX - 0.05, startY + 0.5);
  ctx.lineTo(endX, endY);
  ctx.stroke();

  ctx.closePath();
}

function renderXAxis({ ctx, width, height }) {
  const xInset = constants.CHART.DIMENSIONS.X_AXIS_INSET;
  const startX = xInset;
  const startY = height / 2;
  const endX = width - xInset;
  const endY = startY;

  ctx.beginPath();

  ctx.lineWidth = constants.CHART.DIMENSIONS.X_AXIS_LINE_WIDTH;

  ctx.moveTo(startX, startY);
  ctx.lineTo(endX, endY);
  ctx.stroke();

  ctx.closePath();
}

function renderTimeSeries({ ctx, timeSeries, range, height, width }) {
  const xInset = constants.CHART.DIMENSIONS.X_AXIS_TIME_SERIES_INSET;
  const yInset = constants.CHART.DIMENSIONS.Y_AXIS_TIME_SERIES_INSET;
  const xAxisPx = width - 2 * xInset;
  const yAxisPx = height - 2 * yInset;
  const mappedToAxes = mapTimeSeriesToAxis({ timeSeries, range, xAxisPx, yAxisPx, xInset, yInset });

  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.lineWidth = constants.CHART.DIMENSIONS.X_TIME_SERIES_LINE_WIDTH;
  ctx.strokeStyle = constants.CHART.COLORS.PRIMARY;

  mappedToAxes.forEach(({ x, y }, i, points) => {
    if (i === 0) {
    } else {
      const last = points[i - 1];
      const isInChartBounds = last.x > 0;

      if (isInChartBounds) {
        ctx.beginPath();
        ctx.moveTo(last.x, last.y);
        ctx.lineTo(x, y);
        ctx.stroke();
        ctx.closePath();
      }
    }
  });
}

function mapTimeSeriesToAxis({ timeSeries, range, xAxisPx, yAxisPx, xInset, yInset }) {
  const [start, end] = range;
  const millisPerPx = (end - start) / xAxisPx;

  return timeSeries.map(({ avgSentiment, timestamp }) => {
    const x = Math.round((timestamp - start) / millisPerPx + xInset);
    const sentimentScaledToOne = (-1 * avgSentiment + 1) / 2;
    const y = Math.round(sentimentScaledToOne * yAxisPx + yInset);

    return { x, y };
  });
}

function getGetCoordinates({ timeSeries, range, height, width }) {
  return (timestamp, { resolution }) => {
    const xInset = constants.CHART.DIMENSIONS.X_AXIS_TIME_SERIES_INSET;
    const yInset = constants.CHART.DIMENSIONS.Y_AXIS_TIME_SERIES_INSET;
    const xAxisPx = width - 2 * xInset;
    const yAxisPx = height - 2 * yInset;
    const mappedToAxes = mapTimeSeriesToAxis({
      timeSeries,
      range,
      xAxisPx,
      yAxisPx,
      xInset,
      yInset,
    });
    const unixTimestamp = new Date(timestamp).getTime();
    const index = timeSeries.findIndex(data => data.timestamp === unixTimestamp);
    const nextIndex = timeSeries.findIndex(data => data.timestamp < unixTimestamp);
    const priorIndex = nextIndex - 1;
    const priorCoordinates = mappedToAxes[priorIndex];
    const nextCoordinates = mappedToAxes[nextIndex];
    const exists = !!~index;

    if (priorCoordinates && nextCoordinates) {
      const slope =
        (nextCoordinates.y - priorCoordinates.y) / (nextCoordinates.x - priorCoordinates.x);
      const priorTimestamp = timeSeries[priorIndex].timestamp;
      const nextTimestamp = timeSeries[nextIndex].timestamp;
      const xPercentage = (unixTimestamp - priorTimestamp) / (nextTimestamp - priorTimestamp);
      const xPx = round(
        priorCoordinates.x + xPercentage * (nextCoordinates.x - priorCoordinates.x)
      );
      const yPx = round(slope * (xPx - priorCoordinates.x) + priorCoordinates.y);
      const x = xPx / resolution;
      const y = yPx / resolution;

      return { x, y };
    } else if (exists) {
      const index = timeSeries.findIndex(data => data.timestamp === unixTimestamp);

      const { x: xPx, y: yPx } = mappedToAxes[index];
      const x = xPx ? xPx / resolution : '';
      const y = yPx ? yPx / resolution : '';

      return { x, y };
    } else {
      return { x: '', y: '' };
    }
  };
}

function round(n) {
  return Math.round(ACCURACY * n) / ACCURACY;
}

function renderLeftMask({ ctx, height }) {
  const xInset = constants.CHART.DIMENSIONS.X_AXIS_INSET;
  const startX = 0;
  const startY = 0;
  const rectWidth = xInset - 1;
  const rectHeight = height;

  ctx.beginPath();

  ctx.fillStyle = constants.CHART.COLORS.BACKGROUND;

  ctx.fillRect(startX, startY, rectWidth, rectHeight);

  ctx.closePath();
}
