import React, { useEffect, useRef, useMemo, useState } from 'react';
import useRefDimensions from './use-ref-dimensions';
import renderEkgChart from './render-ekg-chart';
import getTimeSeriesFromEkgData from './get-time-series-from-ekg-data';
import constants from '../../constants';
import EkgCallouts from './EkgCallouts';
import EkgHeader from './EkgHeader';
import EkgTimeline from './EkgTimeline';
import './EkgChart.css';

export default ({
  sentiment = {},
  data = [],
  callouts = [],
  xAxisMinutes,
  xAxisIncrement,
  reverseXAxis,
  xAxisMillis = constants.CHART.X_AXIS_MILLIS,
}) => {
  let xMillis = xAxisMinutes * 60 * 1000;

  const wrapperRef = useRef(null);
  const canvasRef = useRef(null);
  const [getCoordinates, setGetCoordinates] = useState(null);
  const [range, setRange] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo(() => getCtxFromRef(canvasRef), [canvasRef.current]);
  const dimensions = useRefDimensions(wrapperRef);
  const rounded = { width: Math.round(dimensions.width), height: Math.round(dimensions.height) };
  const resolution = constants.CHART.RESOLUTION;
  const scaled = { width: rounded.width * resolution, height: rounded.height * resolution };

  useEffect(() => {
    if (ctx && data.length) {
      const { timeSeries, range } = getTimeSeriesFromEkgData(data, xMillis);
      const { getCoordinates } = renderEkgChart({ ctx, timeSeries, range });

      setRange(range);
      setGetCoordinates(() => getCoordinates);
    }
  }, [ctx, data, dimensions, xMillis]);

  return (
    <div className="ekg-chart-wrapper">
      <EkgHeader sentiment={sentiment} />
      <div className="ekg-chart" ref={wrapperRef}>
        <AxisLabels />
        <canvas
          ref={canvasRef}
          width={scaled.width}
          height={scaled.height}
          style={{ width: rounded.width, height: rounded.height }}
        />
        {getCoordinates && (
          <EkgCallouts
            callouts={callouts}
            getCoordinates={getCoordinates}
            height={rounded.height}
            sentiment={sentiment}
            reverseXAxis={reverseXAxis}
          />
        )}
      </div>
      <EkgTimeline range={range} sentiment={sentiment} xAxisMinutes={xAxisMinutes} xAxisIncrement={xAxisIncrement} reverseXAxis={reverseXAxis} />
    </div>
  );
};

function getCtxFromRef(ref) {
  return ref && ref.current && ref.current.getContext('2d');
}

function AxisLabels() {
  return (
    <>
      <div className="label y-axis-top">
        <span>100</span>
      </div>
      <div className="label y-axis-bottom">
        <span>-100</span>
      </div>
    </>
  );
}
