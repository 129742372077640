import React from 'react';
import '../AppBar.css';
import { Link } from 'react-router-dom'

// MATERIAL UI
import { Button, Menu, MenuItem, Fade } from '@material-ui/core';

// Icons
import SearchIcon from '@material-ui/icons/SearchTwoTone';
import Notification from '../../../assets/svg_icons/Notification'

// FIREBASE
import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

function UserAppBarRight(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const logOut = () => {
    handleClose()
    props.firebase.doSignOut()
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => {
        return(
          <div className='app-bar-right'>
            <div className='search'>
              <SearchIcon />
              <input placeholder='Search feeds, posts or help' aria-describedby="Search"></input>
            </div>
            <div className='app-bar-icon-container'>
              <div id="app-bar-icons">
                <Notification />
              </div>
            </div>
            <div className='app-bar-user-info'>
              <Button onClick={handleClick}>{authUser.displayName}</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Link to='/settings' ><MenuItem onClick={handleClose}>Settings</MenuItem></Link>
                <MenuItem onClick={logOut}>Logout</MenuItem>
              </Menu>
              <img src={authUser.photoURL} alt='User Profile Image' className='user-photo-container'/>
            </div>
          </div>
          )
        }
      }
    </AuthUserContext.Consumer>
  );
	}


export default withFirebase(UserAppBarRight)