import React from 'react'

import BWWChart5Global from '../../../data/BWWChart5-Global.json'
import {ResponsiveSwarmPlot} from '@nivo/swarmplot'

class BWWAnalyticsChart5 extends React.Component {

  state = {
    dataProvider5: BWWChart5Global,
    chart5: [],
    annotationsArray5: []
  };

  handleChange = event => {
    this.setState({chart5: []});
    this.setState({annotationsArray5: []});
    // console.log(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  };


  render() {

    let that = this;

    this.state.chart5 = this.state.dataProvider5.filter(function(el) {
      return (el.OfferType === that.props.selectedOffer1 || el.OfferType === that.props.selectedOffer2) && (el.EventType === that.props.selectedEvent1 || el.EventType === that.props.selectedEvent2) && (el.Huddle === that.props.selectedHuddle);
    });

    this.state.chart5.forEach(function(i, indi) {
      if (i.hasOwnProperty("OfferType")) {
        i.group = i.OfferType;
      }
      if (i.hasOwnProperty("EventType")) {
        i.id = i.EventType+" "+indi;
      }
      if (i.EventType === that.props.selectedEvent2) {
        that.state.annotationsArray5.push({
          type: 'circle',
          match: {
            index: indi
          },
          //noteX: 40,
          //noteY: 40,
          offset: 4,
          //note: that.props.selectedEvent2
        })
      }
      if (i.EventType === that.props.selectedEvent1) {
        that.state.annotationsArray5.push({
          type: 'rect',
          match: {
            index: indi
          },
          //noteX: -40,
          //noteY: -40,
          offset: 4,
          //note: that.props.selectedEvent1
        })
      }
    });

    // console.log(this.props)
    // console.log(this.state)

    return (

      <div>
      <hr/>
	    <div className='row' style={{
	        height: '500px'
	      }}>
	      <ResponsiveSwarmPlot data={this.state.chart5}
					groups={[this.props.selectedOffer1, this.props.selectedOffer2]}
					value="Value"
          valueScale={{
						type: 'linear',
						min: 0,
						max: 150
	        }}
          identity="id"
					annotations={this.state.annotationsArray5}
					spacing={20}
	        // size={{ key: 'Value', values: [ 4, 20 ], sizes: [ 6, 20 ] }}
	        size={12}
          colorBy="group"
          borderColor={{
	          from: [
	            '#D2AA41', '#00B1FF', '#F5EDD8', '#00B1FF'
	          ],
	          modifiers: [
	            [
	              'darker', 0.6
	            ],
	            [
	              'opacity', 0.5
	            ]
	          ]
	        }} margin={{
	          top: 80,
	          right: 100,
	          bottom: 80,
	          left: 100
	        }} axisTop={null} axisRight={null} axisBottom={{
	          orient: 'bottom',
	          tickSize: 10,
	          tickPadding: 5,
	          tickRotation: 0,
	          //legend: 'Offers',
	          legendPosition: 'middle',
	          legendOffset: 46
	        }} axisLeft={{
	          orient: 'left',
	          tickSize: 10,
	          tickPadding: 5,
	          tickRotation: 0,
	          legend: 'Avg $ Per Order',
	          legendPosition: 'middle',
	          legendOffset: -76
	        }} animate={false} theme={{
	          'grid' : {
	            'line': {
	              'stroke': '#EEF5F5',
	              'strokeWidth': '1px'
	            }
	          },
	          'axis' : {
	            'ticks': {
	              'text': {
	                'fill': '#BDD5D7',
	                'fontSize': 18,
	                'fontWeight': '500',
	                'fontFamily': 'Source Sans Pro'
	              }
	            }
	          },
	          'tooltipFormat' : {
	            'container': {
	              'background': 'red'
	            }
	          }
	        }}/>
	    </div>
      </div>

	)
  }
}

export default BWWAnalyticsChart5
