import React from 'react';
import './reset.css'
import './App.css'

// COMPONENTS
import AppBar from './components/AppBar/AppBar'
import Drawer from './components/Drawer/Drawer'
import PageWrapper from './components/PageWrapper/PageWrapper'

// SESSIONS
import { withAuthentication } from './components/Session';


class App extends React.Component {

  render() {
    return(
      <div className='app'>
        <AppBar/>
        <div className='app-container'>
            <Drawer />      
            <PageWrapper />
        </div>
    </div>
    )
  }
}

export default withAuthentication(App)