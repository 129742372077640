import React from 'react'

import { ResponsiveLine } from '@nivo/line'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BWWChart8Global from '../../data/BWWChart8-Global.json'
import BWWChart8Huddle from '../../data/BWWChart8-Huddle.json'
import WidgetHeaderHOC from '../../components/WidgetHeaderHOC/WidgetHeaderHOC.js';

const title = {
  title:'Votes Over Time'
}
class AnalyticsChart8 extends React.Component{
  state={
    chart: [],
    dataset: "1",
    chartData: BWWChart8Global
  };

  handleChange = event => {
    this.setState(
      {dataset: event.target.value}
    );
  };

  render(){
    let datasetVale = this.state.dataset
    this.state.chart = this.state.chartData.filter(function (el) {
       return (el.dataset === datasetVale && el.id === "Responses");
      });
    // console.log(this.state);

    return(
      <div className='vote-graph  primary-border' style={{width: '100%', paddingBottom:'30px'}}>
      <div className='vote-graph-top-bar'>
          <form style={{width:'100%', display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.dataset}
                onChange={this.handleChange}
                displayEmpty
                name="dataType"
              >
              <MenuItem value={'1'}>Hour</MenuItem>
              <MenuItem value={'2'}>Evening</MenuItem>
              <MenuItem value={'3'}>Day</MenuItem>
              <MenuItem value={'4'}>Week</MenuItem>
              <MenuItem value={'5'}>Month</MenuItem>
              <MenuItem value={'6'}>All</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        <div className='chart'>
         <ResponsiveLine
            data={this.state.chart}
            curve='natural'
            margin={{
                "top": 70,
                "right": 40,
                "bottom": 40,
                "left": 70
            }}
            enableGridX={false}
            axisTop={null}
            yScale={{
              'type': 'linear',
              'stacked': true
            }}
            axisRight={null}
            axisBottom={
                {
                "orient": "bottom",
                "tickSize": 0,
                "tickPadding": 10,
                "tickRotation": 45,
                }}
            axisLeft={{
              'orient' : 'left',
              'tickRotation' : 0,
              'legend' : 'Votes',
              'legendOffset' : -60,
              'legendPosition' : 'middle'
            }}
            colors={['var(--primary)']}
            enableDots={false}
            enableDotLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[
                {
                    "anchor": "top-right",
                    "direction": "row",
                    "justify": false,
                    "translateX": 10,
                    "translateY": -50,
                    "itemsSpacing": 0,
                    "itemDirection": "left-to-right",
                    "itemWidth": 80,
                    "itemHeight": 20,
                    "itemOpacity": 0.75,
                    "symbolSize": 20,
                    "symbolShape": "circle",
                    "effects": [
                        {
                            "on": "hover",
                            "style": {
                                "itemBackground": "rgba(0, 0, 0, .03)",
                                "itemOpacity": 1
                            }
                        }
                    ]
                }
            ]}
            theme= {{
                'grid': {
                    'line' : {
                        'stroke': '#EEF5F5',
                        'strokeWidth': '1px'
                    }
                },
                'axis': {
                    'ticks': {
                        'text': {
                            'fill': 'var(--chart-axis-label)',
                            'fontSize': 18,
                            'fontWeight': '500',
                            'fontFamily': 'Source Sans Pro'
                        }
                    },
                    'legend': {
                      'text': {
                        'fontSize': 14
                      }
                    }
                },
                'tooltipFormat': {
                    'container': {
                        'background': 'red'
                    }
                }
            }}
        />
      </div>
      </div>
    )
  }
}

const WrappedComponent = WidgetHeaderHOC(AnalyticsChart8, title)

export default WrappedComponent