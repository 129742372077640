/* globals window */
import constants from '../../constants';
export default class Firebase {
  get auth() {
    return window.firebase.auth();
  }

  get firestore() {
    return window.firebase.firestore();
  }

  get functions() {
    return window.firebase.functions();
  }

  get firestoreRoot() {
    return this.firestore.collection('versions').doc(constants.FIREBASE.VERSION);
  }

  get googleProvider() {
    return new window.firebase.auth.GoogleAuthProvider();
  }

  get facebookProvider() {
    return new window.firebase.auth.FacebookAuthProvider();
  }

  get twitterProvider() {
    return new window.firebase.auth.TwitterAuthProvider();
  }

  getSentimentRefByEventId(eventId) {
    return this.firestoreRoot.collection('sentiment').doc(eventId);
  }

  getAvgSentimentHistoryRefByEventId(eventId) {
    const sentimentRef = this.firestoreRoot.collection('sentiment');

    return sentimentRef.doc(eventId).collection('avgSentimentHistory');
  }

  getPromptsRefByEventId(eventId) {
    const promptsRef = this.firestoreRoot.collection('prompts');

    return promptsRef.where('eventId', '==', eventId);
  }

  flattenSnapshot(snapshot) {
    return snapshot.docs.map(doc => ({ __id: doc.id, ...doc.data() }));
  }

  createTimestamp({ seconds, nanoseconds } = {}) {
    return new window.firebase.firestore.Timestamp(seconds, nanoseconds);
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => {
    return this.auth.signInWithPopup(this.googleProvider);
    // console.log('google')
    // this.auth.setPersistence(this.firebase.auth.Auth.Persistence.LOCAL)
    //   .then(function() {
    //     console.log('ran')
    //     return this.auth.signInWithEmailAndPassword(this.googleProvider);
    //   })
    //   .catch(function(error) {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     console.log('error', errorMessage, errorCode)
    //   });
  };

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doSignOut = () => this.auth.signOut();
}
