import React from 'react'
import { Switch, Route } from 'react-router-dom'

import BWWDashboard from './pages/Dashboard/BWWDashboard'
import BWWAnalytics from './pages/Analytics/BWWAnalytics'
// import Analytics from './Pages/Analytics/Analytics'
import Dashboard from './pages/Dashboard/Dashboard'
import EventAnalysis from './pages/Dashboard/EventAnalysis'
// import Login from './pages/Login/Login';
import DataMarketplace from './pages/DataMarketplace/DataMarketplace';
// import Home from './pages/Home/Home';
import Publisher from './pages/Publisher/Publisher';
import DeepAnalytics from './pages/DeepAnalytics/DeepAnalytics';
import Heatmap from './pages/FullSizeChoropleth/FullSizeChoropleth';
import BrowseEvents from './pages/BrowseEvents/BrowseEvents'
import firebaseServerTest from './components/firebaseServerTest/firebaseServerTest';
import SignUp from './components/SignUp/SignUp';
import SignIn from './components/SignIn/SignIn'
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import settings from './pages/Settings/Settings';

export default (
  <Switch>
    <Route exact path='/' component={BWWDashboard} />
    <Route path='/dashboard' component={BWWDashboard} />
    <Route path='/bww_dashboard' component={BWWDashboard} />
    <Route path='/bww_analytics' component={BWWAnalytics} />
    <Route path='/eventanalysis' component={EventAnalysis} />
    <Route path='/analytics' component={BWWAnalytics} />
    <Route path='/login' component={SignIn} />
    <Route path='/data-marketplace' component={DataMarketplace} />
    <Route path='/publisher' component={Publisher} />
    <Route path='/heatmap' component={Heatmap} />
    <Route path='/browse-events' component={BrowseEvents} />
    <Route path='/firebase-test' component={firebaseServerTest} />
    <Route path='/sign-up' component={SignUp} />
    <Route path='/forgot-password' component={ForgotPassword} />
    <Route path='/settings' component={settings} />
  </Switch>
)
