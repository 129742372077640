import React, { useEffect, useState } from 'react';
import './EkgHeader.css';

export default ({ sentiment }) => {
  const [currentSentiment, setCurrentSentiment] = useState({});
  const [laggedSentiment, setLaggedSentiment] = useState({});
  const rating = currentSentiment.avgSentiment && Math.round(currentSentiment.avgSentiment * 100);

  useEffect(() => {
    setLaggedSentiment(currentSentiment);
    setCurrentSentiment(sentiment);
  }, [sentiment]);

  return (
    <div id="ekg-header">
      <h3>Currently</h3>
      <div className="sub-head rating">
        <Value label="Rating" value={rating} />
        <Change
          current={currentSentiment.avgSentiment}
          lagged={laggedSentiment.avgSentiment}
          calc={(a, b) => Math.round(a - b)}
        />
      </div>
      <div className="sub-head participants">
        <Value
          label="Participants"
          value={currentSentiment.numActiveUsers}
          transform={value => {
            let result = value;

            if (value > 100) {
              const numVal = Math.round((10 * value) / 1000) / 10;

              result = `${numVal}k`;
            }

            return result;
          }}
        />
        <Change
          current={currentSentiment.numActiveUsers}
          lagged={laggedSentiment.numActiveUsers}
          calc={(a, b) => Math.round((100 * (a - b)) / b)}
        />
      </div>
    </div>
  );
};

function Value({ label, value, transform = a => a }) {
  const isDefined = typeof value !== 'undefined';
  const isPositive = value > 0;

  return (
    isDefined && (
      <>
        <span className="value" is-positive={String(isPositive)}>
          {transform(value)}
        </span>
        <span className="label">{label}</span>
      </>
    )
  );
}

function Change({ calc, current, lagged }) {
  const percentageChanged = lagged ? calc(current, lagged) : null;
  const isPositive = percentageChanged > 0;
  const isDefined = typeof lagged !== 'undefined';

  return (
    <span className="change" is-positive={String(isPositive)} is-defined={String(isDefined)}>
      {percentageChanged}
    </span>
  );
}
