import React, { useEffect } from 'react'

import './ChoroplethToolTip.scss'

import numeral from 'numeral'

export default function ChoroplethToolTip(props){
  const {hoverLocation, hoverData} = props
  let formattedHoverData
  if (hoverData >= 1000) {
    formattedHoverData = numeral(hoverData).format('0.0a')
  } else {
    formattedHoverData = numeral(hoverData).format('0,0')
  }

// console.log('formattedHoverData', formattedHoverData)
  return(
    <div className='tooltip tooltip--left' style={{position:'absolute', top:props.top, left:props.left}}>
      <p className='tooltip--title'>{hoverLocation}</p>
      <p className='tooltip--data'>{formattedHoverData} responses</p>
    </div>
  )
}