import React from 'react';

import ChevronLeft from '@material-ui/icons/ChevronLeftTwoTone';

const LeftArrow = (props) => {
  return (
    <div
    className="carousel__arrow carousel__arrow--left"
    onClick={props.onClick}
    >      
      <ChevronLeft/>
    </div>
  );
}

export default LeftArrow;