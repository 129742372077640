import React from 'react'

const WidgetHeaderHigherOrderComponent = (WrappedComponent, ComponentData) => {
  class HOC extends React.Component{
    render(){
      return(
        <div style={{width:'100%'}}>
          <div className='widget-header'>
            <h2>{ComponentData.title}</h2>
          </div>
          <WrappedComponent/>
        </div>
      )
    }
  }
  return HOC
}
export default WidgetHeaderHigherOrderComponent