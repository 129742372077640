import React, { useMemo } from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import constants from '../../constants';
import './EkgCallouts.css';

TimeAgo.addLocale(en);

const timeAgo = new TimeAgo('en-US');

const MIN_X = constants.CHART.DIMENSIONS.X_AXIS_INSET / constants.CHART.RESOLUTION;

export default function Callouts({ callouts, getCoordinates, height, sentiment, reverseXAxis }) {
  const scheduledTimestamp = useMemo(() => {
    if (sentiment.scheduledTime) {
      const parts = sentiment.scheduledTime.match(/([0-9|-]+)T([0-9|:]+)(.*)/).slice(1, 3);
      const formatted = parts.join(' ');
      const date = new Date(formatted);

      return date.getTime();
    }
  }, [sentiment]);

  return (
    <div className="callouts-wrapper">
      {callouts.map(({ timestamp, avgSentiment, text }, i) => {
        const isDarkBool = typeof avgSentiment !== 'undefined';
        const { x, y } = getCoordinates(timestamp, { resolution: constants.CHART.RESOLUTION });
        const hasCoordinates = !!x && !!y;
        const formattedTimestamp = (reverseXAxis == 1) ? formatTimestamp({ scheduledTimestamp, timestamp }) : formatTimestampFullEvent({ scheduledTimestamp, timestamp });
        const standardizedAvgSentiment = avgSentiment ? Math.round(avgSentiment * 100) : '';
        const formattedSentiment = formatSentiment(standardizedAvgSentiment);
        const isPositiveBool = y < 0.5 * height;
        const isInChartBounds = x > MIN_X;
        const isDark = String(isDarkBool);
        const isPositive = String(isPositiveBool);
        const isSentimentBox = !text;

        return (
          hasCoordinates &&
          isInChartBounds && (
            <div
              className="callout"
              key={`${timestamp}-${i}`}
              is-dark={isDark}
              is-positive={isPositive}
              tabIndex="0"
              style={{ left: `${x}px` }}
            >
              <div className="callout-inner" style={{ top: `${y}px` }}>
                <div className="dot" />
                <div className="tooltip-wrapper">
                  {isSentimentBox ? (
                    <SentimentBox sentiment={formattedSentiment} timestamp={formattedTimestamp} />
                  ) : (
                    <CalloutBox
                      sentiment={formattedSentiment}
                      timestamp={formattedTimestamp}
                      text={text}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}

function SentimentBox({ sentiment, timestamp }) {
  return (
    <div className="box sentiment-box">
      <div className="top-bar">
        <span>{sentiment} Rating</span>
      </div>
      <div className="timestamp">{timestamp}</div>
    </div>
  );
}

function CalloutBox({ sentiment, timestamp, text }) {
  return (
    <div className="box callout-box">
      <div className="top-bar">
        <span>{timestamp}</span>
        <span className="flex" />
        <span>{sentiment}</span>
      </div>
      <div className="text">{text}</div>
    </div>
  );
}

function formatTimestamp({ scheduledTimestamp, timestamp }) {
  const diff = timestamp - scheduledTimestamp;
  const now = Date.now();
  const timeAgoTimestamp = now - diff;

  return timeAgo.format(new Date(timeAgoTimestamp), { flavour: 'tiny' });
}

function formatTimestampFullEvent({ scheduledTimestamp, timestamp }) {
  const diff = Math.abs(timestamp - scheduledTimestamp);
  let minutes = Math.floor((diff/1000)/60) - 223;
  return minutes + 'm';
}

function formatSentiment(sentiment) {
  return sentiment > 0 ? `+${sentiment}` : `${sentiment}`;
}
