import React from 'react';
import firebase from '../Firebase';

class firebaseServerTest extends React.Component {
  // handleClick = async () => {
  //   console.log('Button Clicked');
  //   let getTopFeeds = firebase.functions.httpsCallable('dashboardGetTopFeeds');
  //   let results = await getTopFeeds();
  //   console.log(results.data);
  // };

  render() {
    return (
      <div>
        Firebase Cloud Server Test
        <button onClick={e => this.handleClick()}>Test Server</button>
      </div>
    );
  }
}

export default firebaseServerTest;
