import React from 'react';
import './PageWrapper.css'
import routes from '../../routes.js';

import {connect} from 'react-redux'

class PageWrapper extends React.Component {
  state = {

  };

  render() {

    return (
      <div className={this.props.isDrawerOpen ? 'page-wrapper short' : 'page-wrapper'}>
        {routes}
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    isDrawerOpen: state.isDrawerOpen,
  }
}

export default connect(mapStateToProps)(PageWrapper)