import React from 'react';
import './IndividualPost.css'
import numeral from 'numeral'

// Material UI
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button'

const styles = theme => ({
  card: {
    // maxWidth: 400,
    boxShadow: 'none',
    marginBottom: '10px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'var(--primary)',
    width: 60,
    height: 60
  },
  button: {
    width: '100%',
    marginTop: '20px',
    backgroundColor: 'var(--primary)',
    color: '#fff'
  }
});

class RecipeReviewCard extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;
    let post = this.props.post
    let titleString= post.title.split('')
    let avatarLetter = titleString[0]
    return (
      <div className='primary-border'>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              {avatarLetter}
            </Avatar>
          }
          action={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          title={post.title}
          subheader={post.timestamp.value}
        />
        {/* <CardMedia
          className={classes.media}
          image="/static/images/cards/paella.jpg"
          title="Paella dish"
        /> */}
        <CardContent>
          <Typography component="p">
            {post.text}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions} disableActionSpacing>
          <IconButton aria-label="Add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="Share">
            <ShareIcon />
          </IconButton>
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div className='top-post-card-info'>
              <div className='top-post-stack'>
                <p id='top-post-info-top'>
                  {post.totalUserCount > 10 ? numeral(post.totalUserCount).format('0.0a') : numeral(post.totalUserCount).format('00')}
                </p>
                <p id='top-post-info-bottom'>Participants</p>
              </div>
              <div className='top-post-stack'>
                <p id='top-post-info-top'>
                  {post.huddleCount > 10 ? numeral(post.huddleCount).format('0.0a') : numeral(post.huddleCount).format('00')}
                </p>
                <p id='top-post-info-bottom'>Huddles</p>
              </div>
              <div className='top-post-stack'>
                <p id='top-post-info-top'>
                  {numeral(post.answerRate).format('0%')}
                </p>
                <p id='top-post-info-bottom'>Answer Rate</p>
              </div>
            </div>
            <Button variant='contained' className={classes.button}>See Full Analytics</Button>
          </CardContent>
        </Collapse>
      </Card>
      </div>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);