import React, { useMemo } from 'react';
import constants from '../../constants';
import './EkgTimeline.css';

export default ({ range, sentiment, xAxisMinutes, xAxisIncrement, reverseXAxis }) => {
  const labels = useMemo(() => {
    if (range && sentiment) {
      const scheduledTime = new Date(sentiment.scheduledTime);
      const start = new Date(range[0]);
      const end = new Date(range[1]);

      let returnArr = [];

      if (reverseXAxis == 1) {
        for (var i = Number(xAxisMinutes); i >= 0; i -= Number(xAxisIncrement)) {
          returnArr.push(i + 'm');
        }
      } else {
        for (var i = 0; i <= Number(xAxisMinutes); i += Number(xAxisIncrement)) {
          returnArr.push(i + 'm');
        }
      }

      return returnArr;
    } else {
      return [];
    }
  }, [range, sentiment]);

  return (
    <div id="ekg-timeline">
      <ul>
        {labels.map((label, i) => (
          <li key={i}>{label}</li>
        ))}
      </ul>
    </div>
  );
};
