import React from 'react'

import { ResponsiveBar } from '@nivo/bar'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BWWChart9Global from '../../data/BWWChart9-Global.json'
import BWWChart9Huddle from '../../data/BWWChart9-Huddle.json'
import WidgetHeaderHOC from '../../components/WidgetHeaderHOC/WidgetHeaderHOC.js';

const title ={
  title:'User Intensity During Game Driven Events'
}

class AnalyticsChart9 extends React.Component{
  state={
    chart: [],
    dataset: "Basketball",
    chartData: BWWChart9Global
  };

  handleChange = event => {
    this.setState(
      {dataset: event.target.value}
    );
  };

  render(){
    let datasetVale = this.state.dataset
    this.state.chart = this.state.chartData.filter(function(el) {
      return el.SportCategory === datasetVale;
    });

    this.state.chart = this.state.chart.map(s => {
      if (s.hasOwnProperty("EventActivityString")) {
        s.EventActivityString = s.EventActivityString.split("_").join(" ");
      }
      return s;
    })
    // console.log(this.state);

    return(
      <div className='vote-graph  primary-border' style={{width: '100%', paddingBottom:'30px'}}>
      <div className='vote-graph-top-bar'>
          <form style={{width:'100%', display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.dataset}
                onChange={this.handleChange}
                displayEmpty
                name="dataType"
              >
              <MenuItem value={'Basketball'}>Basketball</MenuItem>
              <MenuItem value={'Football'}>Football</MenuItem>
              <MenuItem value={'Soccer'}>Soccer</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        <div className='chart' style={{padding: '10px'}}>
         <ResponsiveBar
            data={this.state.chart}
            keys={["UserIntensity"]}
            indexBy="EventActivityString"
            margin={{
                "top": 0,
                "right": 40,
                "bottom": 70,
                "left": 50
            }}
            enableGridX={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                'orient': 'bottom',
                'tickSize': 5,
                'tickPadding': 5,
                'tickRotation': 0,
                'legend': 'Sport Category',
                'legendOffset': 56,
                'legendPosition': 'middle'
            }}
            axisLeft={{
                'orient': 'left',
                'tickSize': 5,
                'tickPadding': 5,
                'tickRotation': 0,
                // 'legend': 'Event',
                // 'legendOffset': -40,
                // 'legendPosition': 'middle'
            }}
            colors={['var(--highlight-primary']}
            labelTextColor={'var(--primary'}
            borderWidth={2}
            borderColor={'var(--primary'}
            enableDots={false}
            enableDotLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            maxValue={105}
            theme= {{
                'grid': {
                    'line' : {
                        'stroke': '#EEF5F5',
                        'strokeWidth': '1px'
                    }
                },
                'axis': {
                    'ticks': {
                        'text': {
                            'fill': 'var(--chart-axis-label)',
                            'fontSize': 18,
                            'fontWeight': '500',
                            'fontFamily': 'Source Sans Pro'
                        }
                    },
                    'legend': {
                      'text': {
                        'fontSize': 14
                      }
                    }
                },
                'labels': {
                  'text': {
                    'fontSize': 30
                  }
                }
            }}
        />
      </div>
      </div>
    )
  }
}

const WrappedComponent = WidgetHeaderHOC(AnalyticsChart9, title)

export default WrappedComponent
