import React from 'react';

import ChevronRight from '@material-ui/icons/ChevronRightTwoTone';

const RightArrow = (props) => {
  return (
    <div
    className="carousel__arrow carousel__arrow--right"
    onClick={props.onClick}
    >      
      <ChevronRight/>
    </div>
  );
}

export default RightArrow;