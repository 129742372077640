import React from 'react'

class CarouselSlide extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.index === this.props.activeIndex
            ? "carousel__slide carousel__slide--active"
            : "carousel__slide"
        }
      >
        {this.props.slide}
      </div>
    );
  }
}

export default CarouselSlide