import React from 'react'
import './SliderCardTemplate.css'

import cbs from '../../assets/images/CBS.png'
import cowboys_slider_image from '../../assets/images/cowboys_slider_image.jpg'

class SliderCardTemplate extends React.Component {
  render(){
    return(
      <div className='slider-card-clipper'>
        <div className='slider-card'>
          <div className='slider-card-left'>
            <p>National Football League</p>
            <div className='slider-card-watch-block'>
              <p>watch it on</p>
              <img src={cbs} alt=""/>
            </div>
          </div>
          <div className='slider-card-right'>
            <div className='slider-card-right-content'>
              <p>Dallas</p>
              <p>Cowboys</p>
              <p>vs</p>
              <p>Pittsburgh</p>
              <p>Steelers</p>
              <p>June 22, 2019</p>
              <p>8:00 pm</p>
            </div>
            <div>
              <p>at AT&T Stadium, Dallas, TX</p>
            </div>
          </div>
        </div>
          <div className='slider-card-middle'>
            <img src={cowboys_slider_image} alt=''/>
            <div id='slider-card-middle-frame'/>
        </div>
      </div>
    )
  }
}

export default SliderCardTemplate