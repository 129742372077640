import React from 'react'
import './DeepAnalytics.css'
import AnalyticsChart01 from '../../charts/DeepAnalytics/AnalyticsChart01'
import AnalyticsChart02 from '../../charts/DeepAnalytics/AnalyticsChart02'
import AnalyticsChart03 from '../../charts/DeepAnalytics/AnalyticsChart03'
import AnalyticsChart04 from '../../charts/DeepAnalytics/AnalyticsChart04'
import AnalyticsChart05 from '../../charts/DeepAnalytics/AnalyticsChart05'

class DeepAnalytics extends React.Component{
  state={

  }

  render(){
    return(
      <div className='deep-analytics'>
        <AnalyticsChart01 />
        <AnalyticsChart04 />
        <AnalyticsChart05 />
        <AnalyticsChart02 />
        <AnalyticsChart03 />
      </div>
    )
  }
}

export default DeepAnalytics