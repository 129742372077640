import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import CardHOC from '../../cards/CardHOC/CardHOC'
import AnalyticsChart01Data01 from '../../data/AnalyticsChart01_DataSet01.json'
import AnalyticsChart01Data02 from '../../data/AnalyticsChart01_DataSet02.json'
import AnalyticsChart01Data03 from '../../data/AnalyticsChart01_DataSet03.json'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// const barChartData = 

const data = {
  title: 'Feed Responses Over Time',
  description: 'This chart shows how many responses were calculated for each feed and cumulatively over a period of time.',
  url: 'Opinelive.com',
}

class AnalyticsChart1 extends React.Component{
  state={
    timeFrame: AnalyticsChart01Data01
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    return(
      <div className='card-HOC-chart-container'>
        {/* <div className='drop-btn'> */}
          <form style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.timeFrame}
                onChange={this.handleChange}
                displayEmpty
                name="timeFrame"
                className='{classes.selectEmpty}'
              >
                <MenuItem value={AnalyticsChart01Data01}>This Week</MenuItem>
                <MenuItem value={AnalyticsChart01Data02}>Last Week</MenuItem>
                <MenuItem value={AnalyticsChart01Data03}>Last Month</MenuItem>
              </Select>
              {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
          </form>
        {/* </div> */}
        <ResponsiveBar
        data={this.state.timeFrame}
        keys={[ 'The Boyz', 'Higbee Huddle', 'Basketball Friends', 'BYU Fans', 'Opine Crew', 'College Buddies' ]}
        indexBy="day"
        margin={{ top: 10, right: 0, bottom: 20, left: 0 }}
        padding={0.3}
        // layout='horizontal'
        colors={['#002a5c','#33557d','#4d6a8d','#667f9d','#8095ae','#99aabe']}
        isInteractive={true}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'country',
            // legendPosition: 'middle',
            legendOffset: 32
        }}
        // axisLeft={{
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'food',
        //     legendPosition: 'middle',
        //     legendOffset: -40
        // }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor='#fff'
        // legends={[
        //     {
        //         dataFrom: 'keys',
        //         anchor: 'bottom',
        //         direction: 'row',
        //         justify: false,
        //         translateX: 0,
        //         translateY: 60,
        //         itemsSpacing: 2,
        //         itemWidth: 80,
        //         itemHeight: 20,
        //         itemDirection: 'left-to-right',
        //         itemOpacity: 0.85,
        //         symbolSize: 20,
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemOpacity: 1
        //                 }
        //             }
        //         ]
        //     }
        // ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        />
      </div>
    )
  }
}
const WrappedComponent = CardHOC(AnalyticsChart1, data)

export default WrappedComponent