import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import CardHOC from '../../cards/CardHOC/CardHOC'
import AnalyticsChart02Data01 from '../../data/AnalyticsChart02_DataSet01.json'
import AnalyticsChart02Data02 from '../../data/AnalyticsChart02_DataSet02.json'
import AnalyticsChart02Data03 from '../../data/AnalyticsChart02_DataSet03.json'
import AnalyticsChart02Data04 from '../../data/AnalyticsChart02_DataSet04.json'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// const barChartData = 

const data = {
  title: 'Huddle Divergence',
  description: 'This chart shows divergent views across huddles following specific Statements in feeds.',
  url: 'Opinelive.com',
}

class AnalyticsChart2 extends React.Component{
  state={
    timeFrame: AnalyticsChart02Data01
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    return(
      <div className='card-HOC-chart-container'>
          <form style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.timeFrame}
                onChange={this.handleChange}
                displayEmpty
                name="timeFrame"
                className='{classes.selectEmpty}'
              >
                <MenuItem value={AnalyticsChart02Data01}>This Week</MenuItem>
                <MenuItem value={AnalyticsChart02Data02}>Last Week</MenuItem>
                <MenuItem value={AnalyticsChart02Data03}>Last Month</MenuItem>
                <MenuItem value={AnalyticsChart02Data04}>All Time</MenuItem>
              </Select>
            </FormControl>
          </form>
        <ResponsiveBar
        data={this.state.timeFrame}
        keys={[ 'choice 1', 'choice 2' ]}
        indexBy="huddle"
        margin={{ top: 25, right: 20, bottom: 25, left: 30 }}
        padding={0.3}
        colors={['#002a5c','#33557d','#4d6a8d','#667f9d','#8095ae','#99aabe']}
        isInteractive={true}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legendOffset: 32
      }}
        axisRight={null}
        axisBottom={{
            tickSize: 0,
            tickPadding: 15,
            tickRotation: 0,
            legendOffset: 32
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor='#fff'
        labelFormat={v => v<0? -v : v}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        />
      </div>
    )
  }
}
const WrappedComponent = CardHOC(AnalyticsChart2, data)

export default WrappedComponent