const initialState = {
  modalOpen: false,
  componentName: '',
  authUser: null,
  isDrawerOpen: false,
}

//Action Descriptors
const UPDATE_MODAL_OPEN = 'UPDATE_MODAL_OPEN';
const UPDATE_MODAL_CLOSED = 'UPDATE_MODAL_CLOSED';
const LOGOUT_USER = 'LOGOUT_USER';
const LOG_IN_USER = 'LOG_IN_USER';
const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

function reducer(state = initialState, action){
  switch( action.type ){
      case UPDATE_MODAL_OPEN:
        return {...state, modalOpen: action.payload.open, componentName: action.payload.componentName};
   
      case UPDATE_MODAL_CLOSED:
        return {...state, modalOpen: action.payload};

      case LOG_IN_USER:
        return {...state, authUser: action.payload}

      case LOGOUT_USER:
        return {...state, authUser: initialState.authUser}
   
      case TOGGLE_DRAWER:
        return {...state, isDrawerOpen: action.payload}

      default: return state
  }
}

//Action Builders
export function logInUser(authUser) {
  return {
    type: LOG_IN_USER,
    payload: authUser
  }
}

export function updateModalOpen (componentName){
  return {
      type: UPDATE_MODAL_OPEN,
      payload: {
          open: true,
          componentName: componentName
      }
  }
}

export function updateModalClosed (){
  return {
      type: UPDATE_MODAL_CLOSED,
      payload: false
  }
}

export function logoutUser (){
  return {
      type: LOGOUT_USER
  }
}

export function toggleDrawer(prevState){
  return {
    type: TOGGLE_DRAWER,
    payload: !prevState
  }
}

export default reducer;