import React from 'react'
import './Analytics.css'

import BWWChart5Global from '../../data/BWWChart5-Global.json'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BWWAnalyticsChart4 from '../../charts/BWW/Analytics/BWWAnalyticsChart4';
import BWWAnalyticsChart5 from '../../charts/BWW/Analytics/BWWAnalyticsChart5';
import BWWAnalyticsChart6 from '../../charts/BWW/Analytics/BWWAnalyticsChart6';
import BWWAnalyticsChart7 from '../../charts/BWW/Analytics/BWWAnalyticsChart7';

let offer_types_hash = {},
  event_types_hash = {},
  huddle_types_hash = {};

BWWChart5Global.forEach(function(s) {
  offer_types_hash[s.OfferType] = s.OfferType.split("_").join(" ");
  event_types_hash[s.EventType] = s.EventType.split("_").join(" ");
  huddle_types_hash[s.Huddle] = s.Huddle.split("_").join(" ");
});

const offer1 = []
const offer2 = []
Object.keys(offer_types_hash).forEach(function(key) {
  offer1.push(<MenuItem key={key} value={key}>{offer_types_hash[key]}</MenuItem>)
  offer2.push(<MenuItem key={key} value={key}>{offer_types_hash[key]}</MenuItem>)
})

const event1 = []
const event2 = []
Object.keys(event_types_hash).forEach(function(key) {
  event1.push(<MenuItem key={key} value={key}>{event_types_hash[key]}</MenuItem>)
  event2.push(<MenuItem key={key}value={key}>{event_types_hash[key]}</MenuItem>)
})

const huddle = []
Object.keys(huddle_types_hash).forEach(function(key) {
  huddle.push(<MenuItem key={key} value={key}>{huddle_types_hash[key]}</MenuItem>)
})

class BWWAnalytics extends React.Component {

  state = {
    selectedOffer1: Object.keys(offer_types_hash)[0],
    selectedOffer2: Object.keys(offer_types_hash)[1],
    selectedEvent1: Object.keys(event_types_hash)[0],
    selectedEvent2: Object.keys(event_types_hash)[1],
    selectedHuddle: BWWChart5Global[0].Huddle,
    prevScrollpos: window.pageYOffset,
    visible: true
  };

  handleChange = event => {
    // console.log(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    this.setState({
      prevScrollpos: currentScrollPos,
    });
  };

  render() {
    return (
			<div className='dashboard' onScroll={this.handleScroll}>
      <div className='row'>
        <div className='vote-graph' style={{
            width: '100%',
            paddingBottom: '30px'
          }}>
          <div className='form-bar'>
          <div style={{width: "100%", textAlign: "center"}}>
            <div style={{paddingBottom: "20px"}}>
              <h1 className='highlight-primary'>Events &amp; Offers Analysis</h1>
            </div>
          </div>
            <form autoComplete="off" style={{
                width: '90%',
                marginLeft: '3%',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <FormControl variant="filled">
                <Select value={this.state.selectedOffer1} onChange={this.handleChange} displayEmpty="displayEmpty" name="selectedOffer1">
                  {offer1}
                </Select>
              </FormControl>
              <FormControl variant="filled">
                <Select value={this.state.selectedOffer2} onChange={this.handleChange} displayEmpty="displayEmpty" name="selectedOffer2">
                  {offer2}
                </Select>
              </FormControl>
              <FormControl variant="filled">
                <Select value={this.state.selectedEvent1} onChange={this.handleChange} displayEmpty="displayEmpty" name="selectedEvent1">
                  {event2}
                </Select>
              </FormControl>
              <FormControl variant="filled">
                <Select value={this.state.selectedEvent2} onChange={this.handleChange} displayEmpty="displayEmpty" name="selectedEvent2">
                  {event2}
                </Select>
              </FormControl>
              <FormControl variant="filled">
                <Select value={this.state.selectedHuddle} onChange={this.handleChange} displayEmpty="displayEmpty" name="selectedHuddle">
                  {huddle}
                </Select>
              </FormControl>
            </form>
          </div>

          <div className='chart' style={{
              padding: '10px',
              height: '2500px'
            }}>

						<BWWAnalyticsChart4 selectedOffer1={this.state.selectedOffer1} selectedOffer2={this.state.selectedOffer2} selectedEvent1={this.state.selectedEvent1} selectedEvent2={this.state.selectedEvent2} selectedHuddle={this.state.selectedHuddle}/>
            <BWWAnalyticsChart5 selectedOffer1={this.state.selectedOffer1} selectedOffer2={this.state.selectedOffer2} selectedEvent1={this.state.selectedEvent1} selectedEvent2={this.state.selectedEvent2} selectedHuddle={this.state.selectedHuddle}/>
            <BWWAnalyticsChart6 selectedOffer1={this.state.selectedOffer1} selectedOffer2={this.state.selectedOffer2} selectedEvent1={this.state.selectedEvent1} selectedEvent2={this.state.selectedEvent2} selectedHuddle={this.state.selectedHuddle}/>
            <BWWAnalyticsChart7 selectedOffer1={this.state.selectedOffer1} selectedOffer2={this.state.selectedOffer2} selectedEvent1={this.state.selectedEvent1} selectedEvent2={this.state.selectedEvent2} selectedHuddle={this.state.selectedHuddle}/>

          </div>
        </div>
      </div>
    </div>
		)
  }
}

export default BWWAnalytics
