import React, { useCallback, useState } from 'react';
import Firebase from '../../components/Firebase';
import constants from '../../constants';

const firebase = new Firebase();

export const EkgSentimentContext = React.createContext();

export default ({ children }) => {
  const [sentimentHistoryByEventId, setSentimentHistoryByEventId] = useState({});
  const [sentimentByEventId, setSentimentByEventId] = useState({});
  const addSentiments = useCallback(
    (eventId, newRecords = []) => {
      const cachedRecords = getSentimentFromLocalStorageByEventId(eventId);
      const newestId = newRecords.length && newRecords[0].__id;
      const lastCachedId = cachedRecords.length && cachedRecords[0].__id;

      if (newestId !== lastCachedId) {
        const records = [...newRecords, ...cachedRecords];

        saveSentimentFromLocalStorageByEventId(eventId, records);

        setSentimentHistoryByEventId(sentimentHistoryByEventId => ({
          ...sentimentHistoryByEventId,
          [eventId]: records,
        }));
      }
    },
    [setSentimentHistoryByEventId]
  );
  const handleCurrentSnapshot = useCallback(
    doc => {
      setSentimentByEventId(sentimentByEventId => ({
        ...sentimentByEventId,
        [doc.id]: doc.data(),
      }));
    },
    [addSentiments]
  );
  const getHandleHistorySnapshot = useCallback(
    eventId => snapshot => {
      const newRecords = firebase.flattenSnapshot(snapshot);

      addSentiments(eventId, newRecords);
    },
    [addSentiments]
  );
  const listen = useCallback(
    async eventId => {
      const currentSentimentRef = firebase.getSentimentRefByEventId(eventId);
      const unlistenCurrent = currentSentimentRef.onSnapshot(handleCurrentSnapshot);

      const historyQuery = getHistoryQuery(eventId);
      const historySnapshot = await historyQuery.get();
      const handleHistorySnapshot = getHandleHistorySnapshot(eventId);

      handleHistorySnapshot(historySnapshot);

      const unlistenHistory = historyQuery.limit(1).onSnapshot(handleHistorySnapshot);

      return () => (unlistenCurrent(), unlistenHistory());
    },
    [addSentiments]
  );

  return (
    <EkgSentimentContext.Provider value={{ listen, sentimentByEventId, sentimentHistoryByEventId }}>
      {children}
    </EkgSentimentContext.Provider>
  );
};

function getHistoryQuery(eventId) {
  const cachedRecords = getSentimentFromLocalStorageByEventId(eventId);
  const mostRecentCachedRecord = cachedRecords[0];
  const avgSentimentHistoryRef = firebase.getAvgSentimentHistoryRefByEventId(eventId);
  const unorderedQuery = mostRecentCachedRecord
    ? avgSentimentHistoryRef.where('timestamp', '>', mostRecentCachedRecord.timestamp)
    : avgSentimentHistoryRef;

  return unorderedQuery.orderBy('timestamp', 'desc');
}

function getSentimentFromLocalStorageByEventId(eventId) {
  const key = getKey(eventId);
  const sentimentString = localStorage.getItem(key);
  const records = JSON.parse(sentimentString || '[]');

  return records.map(record => ({
    ...record,
    timestamp: firebase.createTimestamp(record.timestamp),
  }));
}

function saveSentimentFromLocalStorageByEventId(eventId, records) {
  const sentimentString = JSON.stringify(records);
  const key = getKey(eventId);

  localStorage.setItem(key, sentimentString);
}

function getKey(eventId) {
  return constants.LOCAL_STORAGE.SENTIMENT_BY_ID_PREFIX + eventId;
}
