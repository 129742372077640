import React from 'react';
import Plot from 'react-plotly.js';
import { Link } from 'react-router-dom'

import stateResponseData from '../../data/AnalyticsChartStates.json'
import './Choropleth.css'

import RankingTable from '../../components/RankingTable/RankingTable'

import Firebase from '../../components/Firebase'
const firebase = new Firebase()

class Choropleth extends React.Component {
  state= {
    codes: ['Al'],
    exports: [12],
    states: ['Alabama'],
    topFive: []
  }

  codes(data){
    var stateCodes = []
    data.map(e => {
      return stateCodes.push(e.code)
    })
    return stateCodes
  }

  totalResponses(data){
    var totalResponses = []
    data.map(e => {
      return totalResponses.push(e.event.event1.feed1.post1.week2.totalResponses)
    })
    return totalResponses
  }

  states(data){
    var states = []
    data.map(e => {
      return states.push(e.state)
    })
    return states
  }


  async componentDidMount(){    
		let getTopFiveLocaitons =  firebase.functions.httpsCallable('dashboardGetTopFeeds')
    let topFiveResults = await getTopFiveLocaitons()

		this.setState({
			topFive: topFiveResults.data,
    })
  }

  render() {
    return (
      <div className='third'>
        <div className='widget-header'>
          <h2>Votes by State</h2>
          <Link to='/heatmap' >
            <p>See Full Map</p>
          </Link>
        </div>
        <div className='choropleth-container primary-border'>
          <p className='description-text'>Hover to see engagement by state.</p>
          <Plot
            data={[
            {type: 'choropleth',
            locationmode: 'USA-states',
            locations: this.codes(stateResponseData),
            z: this.totalResponses(stateResponseData),
            text: this.states(stateResponseData),
            zmin: 0,
            zmax: 17000,
            colorscale: [
              [0, '#e6eaef'], [0.2, '#b3bfce'],
              [0.4, '#8095ae'], [0.6, '#667f9d'],
              [0.8, '#33557d'], [1, '#002a5c']
          ],
            showscale: false,
            marker: {
                line:{
                    color: 'rgb(255,255,255)',
                    width: 2
                }
            }
            }
            ]}
            layout={{
              dragmode: false,
              // paper_bgcolor: 'red',
              // autosize: true,
              height: 200,
              margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 0
              },
              geo:{
                scope: 'usa',
                showlakes: true,
                lakecolor: 'rgb(255,255,255)',
                // bgcolor: 'purple',
              },
              scene: {
                bgcolor: 'purple'
              }
            }}
            useResizeHandler = {true}
            style={{width:'100%'}}
            displayModeBar = {false}
          />
          <div style={{ margin:'0 auto', marginTop:'30px', height:'265px', width:'100%'}}>
            <RankingTable map={'us'}/>
          </div>
        </div>
      </div>
    );
  }
}


export default Choropleth