import React from 'react';

import { ForgotPasswordForm } from '../../components/ForgotPassword/ForgotPassword';
import PasswordChangeForm from '../../components/ChangePassword/ChangePassword';

const AccountPage = () => (
  <div>
    <h1>Account Page</h1>
    <ForgotPasswordForm />
    <PasswordChangeForm />
  </div>
);

export default AccountPage;