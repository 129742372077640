import React, { useState, useEffect } from 'react'

import numeral from 'numeral'

import './RankingTable.scss'

// STATE CONVERSION CHARTS
import fipsToState from '../../data/d3_Maps_GeoData/codeConversions/fipsToState.json'

import Firebase from '../../components/Firebase'
import Loading from '../Loading/Loading';
const firebase = new Firebase()


export default function RankingTable(props) {
  const [topFive, setTopFive] = useState({doneloading:false})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {    
    console.log('rendered')
    let interval = props.date ? {interval:props.date} : {interval:0}
    
    let fetchData = async () => {
      if(props.map === 'us'){
        setIsLoading(true)
        let getTopFiveLocaitons =  firebase.functions.httpsCallable('dashboardGetTopFiveStates')
        let topFiveResults = await getTopFiveLocaitons(interval)
        console.log('top5',topFiveResults)
      // let topFiveResults = await axios.post('/api/getTopFiveStates',interval)
      let namedTopFive = []
      topFiveResults.data.map(state => {
        let name = getStateName(state.stateabbrev)
        namedTopFive.push({
          name,
          responsecount: state.responsecount
        })
      })
      console.log('top5 states', namedTopFive)
      setTopFive(namedTopFive)
      setIsLoading(false)

      } else {
        let getTopFiveLocaitons =  firebase.functions.httpsCallable('dashboardGetTopFiveCountries')
        let topFiveResults = await getTopFiveLocaitons(interval)
        console.log('top5 states', topFiveResults.data)

        setTopFive(topFiveResults.data)
        setIsLoading(false)
      }
    }
    fetchData()
    // return () => {
    //   setIsLoading(true)
    // }
  },[props.map, props.date])


  let getStateName = (stateabbrev) => {
    let stateName = fipsToState.filter(state => {
      return state.abbreviation === stateabbrev
    })
    // console.log('statename', stateName[0])
    return stateName[0].name
  }

  // console.log('isLoading', isLoading)
  return(
    <div>
      {isLoading ? 
      <Loading/>
      :
      <div className='state-table'>
        <div className='colored-engagement-bar'>
          <span>Engagement</span> | Top 5 by Location
        </div>
        <table>
          <tr>
            <th>Rank</th>
            <th>{props.map === 'us' ? 'State' : 'Country'}</th>
            <th>Votes</th>
            <th>Dif</th>
            <th>Res</th>
          </tr>
        {topFive.map((location,i) => {
          return (
          <tr key={location.name}>
            <td>{i + 1}</td>
            <td>{props.map === 'us' ? location.name : location.country}</td>
            <td>{location.responsecount > 999 ?
              numeral(location.responsecount).format('0.0a')
            :
              numeral(location.responsecount).format('0,0')}
            
            </td>
            <td>{Math.floor(Math.random() * 100) + 1 + '%'}</td>
            <td>{Math.floor(Math.random() * 100) + 1 + '%'}</td>
          </tr>
          )
        })}
        </table>
      </div>}
      </div>
  )
}