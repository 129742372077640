import React from 'react'
import OverviewWidget from '../../widgets/OverviewWidget/OverviewWidget'
import AnalyticsChart3 from '../../charts/BWW/BWWChart03'
import AnalyticsChart8 from '../../charts/BWW/BWWChart08'
import AnalyticsChart1 from '../../charts/BWW/BWWChart01'
import AnalyticsChart9 from '../../charts/BWW/BWWChart09'
import AnalyticsChart10 from '../../charts/BWW/BWWChart10'
import AnalyticsChart11 from '../../charts/BWW/BWWChart11'
import AnalyticsChart12 from '../../charts/BWW/BWWChart12'

import './Dashboard.css'
import PropTypes from 'prop-types'
import bwwLogo from '../../assets/bww.png'


class BWWDashboard extends React.Component {
	state= {
		logo: bwwLogo,
		name: 'Buffalo Wild Wings'
	}

	render() {
		return (
			<div className='dashboard'>
        <div className='row'>
					<OverviewWidget data={this.state}/>
          <AnalyticsChart3 />
        </div>
				<div className='row'>
					<AnalyticsChart8 />
				</div>
				<div className='row'>
					<AnalyticsChart1 />
				</div>
				<div className='row'>
					<AnalyticsChart9 />
				</div>
				<div className='row'>
					<AnalyticsChart10 />
				</div>
				<div className='row'>
					<AnalyticsChart11 />
				</div>
				<div className='row'>
					<AnalyticsChart12 />
				</div>
			</div>
		)
	}
}

export default BWWDashboard
