import React from 'react'

import { ResponsiveLine } from '@nivo/line'
import BWWChart12AverageRevenuePerTicket from '../../data/BWWChart12-AverageRevenuePerTicket.json'
import BWWChart12TotalOrders from '../../data/BWWChart12-TotalOrders.json'
import BWWChart12TotalRevenue from '../../data/BWWChart12-TotalRevenue.json'
import WidgetHeaderHOC from '../../components/WidgetHeaderHOC/WidgetHeaderHOC.js';
import Divider from '@material-ui/core/Divider';

const title ={
  title:'Effect of Game Driven Events and Offers'
}
class AnalyticsChart12 extends React.Component{
  state={
    chartData1: [],
    chartData2: [],
    chartData3: [],
    customBulletData: []
  };

  render(){
    let chartData1 = [], chartData2 = [], chartData3 = [], InGameActivityYNArray=[];
    let convertDate = function(dt, add_am_factor){
      let tmp = new Date(dt), add24 = 0;
      if(add_am_factor===true){ add24 = 24 }
      tmp = ((tmp.getHours()<12 ? tmp.getHours() + add24 : tmp.getHours()) + '' + ((tmp.getMinutes()<10?'0':'') + tmp.getMinutes()))
      return parseInt(tmp)
    };

    BWWChart12TotalRevenue.forEach(function(i) {
      chartData1.push({x: i.Timestamp, y: i.Value})
      if(i.InGameActivityYN === true){InGameActivityYNArray.push(convertDate(i.Timestamp, true))}
    });
    BWWChart12AverageRevenuePerTicket.forEach(function(i) {
      chartData2.push({x: i.Timestamp, y: i.Value})
    });
    BWWChart12TotalOrders.forEach(function(i) {
      chartData3.push({x: i.Timestamp, y: i.Value})
    });

    // console.log(InGameActivityYNArray)

    // console.log("1",(convertDate(chartData1[chartData1.length-1].x, true)));
    // console.log("2",convertDate(chartData1[0].x, true));

    let width = 100 / (convertDate(chartData1[chartData1.length-1].x, true) - convertDate(chartData1[0].x, true));
    let divElem = [];
    for(let i = convertDate(chartData1[0].x, true); i < convertDate(chartData1[chartData1.length-1].x, true); i++){
      if(InGameActivityYNArray.indexOf(i) > -1){
        divElem.push(<div key={i} style={{width: ""+width+"%",height: "30px",backgroundColor: "var(--secondary)", display: "inline-block"}}></div>)
      }
      else{
        divElem.push(<div key={i} style={{width: ""+width+"%",height: "50px", display: "inline-block"}}></div>)
      }
    }

    this.state.customBulletData = divElem

    this.state.chartData1 = [
    {
      dataset: 'Average Revenue Per Ticket',
      id: 'Average Revenue Per Ticket',
      data: chartData2
    }]

    this.state.chartData2 = [
    {
      dataset: 'Total Revenue',
      id: 'Total Revenue',
      data: chartData1
    }]

    this.state.chartData3 = [
    {
      dataset: 'Total Orders',
      id: 'Total Orders',
      data: chartData3
    }]

    console.log(this.state);


    return (
      <div className='vote-graph  primary-border' style={{width: '100%', paddingBottom: '30px'}}>
      <div className='vote-graph-top-bar'style={{paddingBottom: '30px'}}></div>
      
      
      <h3 style={{marginTop: "20px"}} className='highlight-primary'>In Game Activity Indicator</h3>
      <div style={{
          width: "100%",
          height: "30px",
          marginBottom: "10px"}}>
          {this.state.customBulletData}
      </div>
      <div className='vote-graph-top-bar'style={{paddingBottom: '20px'}}></div>

      <div className='chart' style={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <ResponsiveLine
          data={this.state.chartData1}
          curve='monotoneX'
          margin={{
            'right' : 20,
            'bottom' : 100,
            'left' : 70
          }}
          xScale={{
            'type': 'time',
            'format': '%m/%d/%Y %H:%M:%S %p',
            'precision': 'minute'
           }}
           yScale={{ type: 'linear', min: 'auto', max: '129' }}
           enableGridX={false}
           axisTop={null}
           axisRight={null}
           axisBottom={{
            'format': '%I:%M %p',
            'tickValues': 'every 30 minutes',
            'orient' : 'bottom',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 90,
            // 'legend' : 'Real Time',
            // 'legendOffset' : 36,
            // 'legendPosition' : 'middle'
          }}
          axisLeft={{
            'orient' : 'left',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 0,
            'legend' : 'Average Revenue Per Ticket ($)',
            'legendOffset' : -60,
            'legendPosition' : 'middle'
          }}
          colors={'var(--primary)'}
          enableDots={false}
          enableDotLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[{
              'anchor': 'top-right',
              'direction': 'row',
              'justify': false,
              'translateX': 10,
              'translateY': -50,
              'itemsSpacing': 10,
              'itemDirection': 'left-to-right',
              'itemWidth': 140,
              'itemHeight': 20,
              'itemOpacity': 0.75,
              'symbolSize': 20,
              'symbolShape': 'circle',
              'effects': [
                {
                  'on': 'hover',
                  'style': {
                    'itemBackground': 'rgba(0, 0, 0, .03)',
                    'itemOpacity': 1
                  }
                }
              ]
            }
          ]}
          theme={{
            'grid' : {
              'line': {
                'stroke': '#EEF5F5',
                'strokeWidth': '1px'
              }
            },
            'axis' : {
              'ticks': {
                'text': {
                  'fill': 'var(--chart-axis-label)',
                  'fontSize': 18,
                  'fontWeight': '500',
                  'fontFamily': 'Source Sans Pro'
                }
              },
              'legend': {
                'text': {
                  'fontSize': 18
                }
              }
            },
            'tooltipFormat' : {
              'container': {
                'background': 'red'
              }
            }
          }}
          />
      </div>

      <div style={{margin:'70px 0 50px 0'}}>
        <hr />
      </div>
      
      <h3 style={{marginTop: "20px"}} className='highlight-primary'>In Game Activity Indicator</h3>
      <div style={{
          width: "100%",
          height: "30px",
          marginBottom: "10px"}}>
          {this.state.customBulletData}
      </div>
      <div className='vote-graph-top-bar'style={{paddingBottom: '20px'}}></div>

      <div className='chart' style={{
          padding: '10px'
        }}>
        <ResponsiveLine
          data={this.state.chartData2}
          curve='monotoneX'
          margin={{
            'right' : 20,
            'bottom' : 100,
            'left' : 100
          }}
          xScale={{
            'type': 'time',
            'format': '%m/%d/%Y %H:%M:%S %p',
            'precision': 'minute'
           }}
           yScale={{ type: 'linear', min: 'auto', max: '27000' }}
           enableGridX={false}
           axisTop={null}
           axisRight={null}
           axisBottom={{
            'format': '%I:%M %p',
            'tickValues': 'every 30 minutes',
            'orient' : 'bottom',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 90,
            // 'legend' : 'Real Time',
            // 'legendOffset' : 36,
            // 'legendPosition' : 'middle'
          }}
          axisLeft={{
            'orient' : 'left',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 0,
            'legend' : 'Total Revenue ($)',
            'legendOffset' : -90,
            'legendPosition' : 'middle'
          }}
          colors={['#F5EDD8']}
          enableDots={false}
          enableDotLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[{
              'anchor': 'top-right',
              'direction': 'row',
              'justify': false,
              'translateX': 10,
              'translateY': -50,
              'itemsSpacing': 10,
              'itemDirection': 'left-to-right',
              'itemWidth': 140,
              'itemHeight': 20,
              'itemOpacity': 0.75,
              'symbolSize': 20,
              'symbolShape': 'circle',
              'effects': [
                {
                  'on': 'hover',
                  'style': {
                    'itemBackground': 'rgba(0, 0, 0, .03)',
                    'itemOpacity': 1
                  }
                }
              ]
            }
          ]}
          theme={{
            'grid' : {
              'line': {
                'stroke': '#EEF5F5',
                'strokeWidth': '1px'
              }
            },
            'axis' : {
              'ticks': {
                'text': {
                  'fill': 'var(--chart-axis-label)',
                  'fontSize': 18,
                  'fontWeight': '500',
                  'fontFamily': 'Source Sans Pro'
                }
              },
              'legend': {
                'text': {
                  'fontSize': 18
                }
              }
            },
            'tooltipFormat' : {
              'container': {
                'background': 'red'
              }
            }
          }}
          />
      </div>

      <div style={{margin:'70px 0 50px 0'}}>
        <hr />
      </div>
      
      <h3 style={{marginTop: "20px"}} className='highlight-primary'>In Game Activity Indicator</h3>
      <div style={{
          width: "100%",
          height: "30px",
          marginBottom: "10px"}}>
          {this.state.customBulletData}
      </div>
      <div className='vote-graph-top-bar'style={{paddingBottom: '20px'}}></div>

      <div className='chart' style={{
          padding: '10px'
        }}>
        <ResponsiveLine
          data={this.state.chartData3}
          curve='monotoneX'
          margin={{
            'right' : 20,
            'bottom' : 100,
            'left' : 70
          }}
          xScale={{
            'type': 'time',
            'format': '%m/%d/%Y %H:%M:%S %p',
            'precision': 'minute'
           }}
           yScale={{ type: 'linear', min: 'auto', max: '1090' }}
           enableGridX={false}
           axisTop={null}
           axisRight={null}
           axisBottom={{
            'format': '%I:%M %p',
            'tickValues': 'every 30 minutes',
            'orient' : 'bottom',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 90,
            // 'legend' : 'Real Time',
            // 'legendOffset' : 36,
            // 'legendPosition' : 'middle'
          }}
          axisLeft={{
            'orient' : 'left',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 0,
            'legend' : 'Total Orders',
            'legendOffset' : -60,
            'legendPosition' : 'middle',
          }}
          colors={['#D2AA41']}
          enableDots={false}
          enableDotLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[{
              'anchor': 'top-right',
              'direction': 'row',
              'justify': false,
              'translateX': 10,
              'translateY': -50,
              'itemsSpacing': 10,
              'itemDirection': 'left-to-right',
              'itemWidth': 140,
              'itemHeight': 20,
              'itemOpacity': 0.75,
              'symbolSize': 20,
              'symbolShape': 'circle',
              'effects': [
                {
                  'on': 'hover',
                  'style': {
                    'itemBackground': 'rgba(0, 0, 0, .03)',
                    'itemOpacity': 1
                  }
                }
              ]
            }
          ]}
          theme={{
            'grid' : {
              'line': {
                'stroke': '#EEF5F5',
                'strokeWidth': '1px'
              }
            },
            'axis' : {
              'ticks': {
                'text': {
                  'fill': 'var(--chart-axis-label)',
                  'fontSize': 18,
                  'fontWeight': '500',
                  'fontFamily': 'Source Sans Pro'
                }
              },
              'legend': {
                'text': {
                  'fontSize': 18
                }
              }
            },
            'tooltipFormat' : {
              'container': {
                'background': 'red'
              }
            }
          }}
          />
      </div>
    </div>



  )
  }
}

const WrappedComponent = WidgetHeaderHOC(AnalyticsChart12, title)

export default WrappedComponent
