import React from 'react'
import '../CardHOC/CardHOC.css'

import Divider from '@material-ui/core/Divider'

import logo from '../../assets/images/OpineLogo.svg'
import share from '../../assets/svg_icons/share.svg'
import embed from '../../assets/svg_icons/embed.svg'
import download from '../../assets/svg_icons/download.svg'

const DataMarketplaceHigherOrderComponent = (WrappedComponent, ComponentData) => {
  class HOC extends React.Component{
    render(){
      return(
        <div className='card-HOC primary-border'>
          <div className='card-HOC-top'>
              <h1 className='card-hoc-H1 '>{ComponentData.title}</h1>
              <Divider variant="middle" style={{
                background: 'var(--highlight-secondary)',
                height: '4px',
                width: '50px',
                margin: '40px 0',
                }}
              />
              <h2 className='card-hoc-H2'>{ComponentData.description}</h2>
          </div>
          <div className='card-hoc-middle'>
            <div className='card-HOC-chart-container'>
            <WrappedComponent/>
            </div>
            <div className='card-hoc-ShareContainer '>
                <div className='card-hoc-ShareItems'>
                  <img src={share} alt='' />
                  <p className='card-hoc-ShareText'>Share</p>
                </div>
                <div className='card-hoc-ShareItems'>                
                  <img src={embed} alt='' />
                  <p className='card-hoc-ShareText'>Embed</p>
                </div>
                <div className='card-hoc-ShareItems'>
                  <img src={download} alt='' />
                  <p className='card-hoc-ShareText'>Download</p>
                </div>
              </div>
          </div>
          <div className='card-hoc-DataMarketplace-Branding'>
            <img src={logo} alt='' />
            <p className='card-hoc-Url'>{ComponentData.url}</p>
          </div>
        </div>
      )
    }
  }
  return HOC
}

export default DataMarketplaceHigherOrderComponent