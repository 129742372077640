import React from 'react'
import './VoteGraphWidget.css'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';

// Charts
import PostsVsResponses_Data01 from '../../data/PostsVsResponses_Data01.json'
import PostsVsResponses_Data02 from '../../data/PostsVsResponses_Data02.json'
import PostsVsResponses_Data03 from '../../data/PostsVsResponses_Data03.json'
import PostsVsResponses_Data04 from '../../data/PostsVsResponses_Data04.json'
import PostsVsResponses_Data05 from '../../data/PostsVsResponses_Data05.json'
import PostsVsResponses_Data06 from '../../data/PostsVsResponses_Data06.json'

// Icons
import upArrow from '../../assets/icons/UpArrow.png'
import VotesVsPostsChart from '../../charts/VotesVsPostsChart';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
		color: '#7D8995',
    borderRadius: 4,
    // backgroundColor: theme.palette.background.paper,
		width: '130px',
    // fontSize: '.875em',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
			'Source Sans Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(InputBase);

const styles = theme => ({
  root: {
    display: 'flex',
		flexWrap: 'wrap',
  },
  bootstrapFormLabel: {
    fontSize: 14,
  },
});

class OverviewWidget extends React.Component {
	state = {
		chart: PostsVsResponses_Data01,
		name: 'hai',
		labelWidth: 0,
	}

  handleChange = event => {
    this.setState({ chart: event.target.value });
  };
	
	render() {
		const { classes } = this.props
		return (
			<div className='two-thirds'>
				<div className='widget-header'>
					<h2>Follower Engagement</h2>
					<p>See More</p>
				</div>
				<div className='vote-graph primary-border'>
					<div className='vote-graph-top-bar'>
						<div className='vote-graph-trends'>
							<div className='vote-graph-top-bar-third'>
								<p className='vote-graph-top-bar-count highlight-primary'>1.9m</p>
								<p>Votes</p>
								<img src={upArrow} alt='Up Arrow' id='arrow-spacer'/>
								<p className='percent-up'>13.8%</p>
							</div>
							<div className='vote-graph-top-bar-third'>
								<p className='vote-graph-top-bar-count highlight-secondary'>319</p>
								<p>Posts</p>
								<img src={upArrow} alt='Up Arrow' id='arrow-spacer'/>
								<p className='percent-up'>13.8%</p>
							</div>
						</div>
						<div className='drop-btn'>
							<form className={classes.root} autoComplete="off">
								<FormControl className={classes.margin}>
								{/* <InputLabel htmlFor="time-customized-select" className={classes.bootstrapFormLabel}>
									time
								</InputLabel> */}
								<Select
									value={this.state.chart}
									onChange={this.handleChange}
									input={<BootstrapInput name="chart" id="age-customized-select" />}
								>
									<MenuItem value={PostsVsResponses_Data01}>Last Hour</MenuItem>
									<MenuItem value={PostsVsResponses_Data02}>Last 3 Hours</MenuItem>
									<MenuItem value={PostsVsResponses_Data03}>Last 24 Hours</MenuItem>
									<MenuItem value={PostsVsResponses_Data04}>Last Week</MenuItem>
									<MenuItem value={PostsVsResponses_Data05}>Last Month</MenuItem>
									<MenuItem value={PostsVsResponses_Data06}>Last 6 months</MenuItem>
								</Select>
								</FormControl>
							</form>
						</div>
        </div>
				<div className='chart'>
					<VotesVsPostsChart chart={this.state.chart}/>
				</div>
			</div>
			</div>
		)
	}
}

OverviewWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OverviewWidget)