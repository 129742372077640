import React from "react";
import './HeatmapSidebar.scss'

// MATERIAL UI
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from "axios";

class HeatmapSidebar extends React.Component {
  render(){
    return(
      <div className='heatmap__sidebar'>
        <div className='heatmap__sidebar--header'>
          <h1>Engagement <br/><span>by Location</span></h1>
          <h2>See your Data in <br/>a new dimension. </h2>
        </div>
        <form autoComplete="off" className='heatmap__sidebar--form'>
          <FormControl variant="filled" className='heatmap__sidebar--form--control'>
            <Select
              value={this.props.select.date}
              onChange={this.props.handleChange}
              displayEmpty
              name="date"
              className='heatmap__sidebar--form-items'
            >
              <MenuItem value={0}>All Dates</MenuItem>
              <MenuItem value={7}>This 7 Days</MenuItem>
              <MenuItem value={14}>Last 14 Days</MenuItem>
              <MenuItem value={21}>Last 21 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={90}>Last 90 Days</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" className='heatmap__sidebar--form--control'>
            <Select
              value={this.props.select.event}
              onChange={this.props.handleChange}
              displayEmpty
              name="event"
              className='heatmap__sidebar--form-items'
            >
              <MenuItem value={'all'}>All Events</MenuItem>
              <MenuItem value={'lakersKnicks'}>Lakers vs Knicks</MenuItem>
              <MenuItem value={'jazzWarriors'}>Jazz vs Warriors</MenuItem>
              <MenuItem value={'timbersRaptors'}>Timbers vs Raptors</MenuItem>
              <MenuItem value={'heatCavs'}>Heat vs Cavs</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" className='heatmap__sidebar--form--control'>
            <Select
              value={this.props.select.feed}
              onChange={this.props.handleChange}
              displayEmpty
              name="feed"
              className='heatmap__sidebar--form-items'
            >
              <MenuItem value={'all'}>All Feeds</MenuItem>
              <MenuItem value={'offense'}>Only Offense</MenuItem>
              <MenuItem value={'defense'}>Just the Defense</MenuItem>
              <MenuItem value={'knicks'}>Knicks Only</MenuItem>
              <MenuItem value={'jazz'}>Nothing but Jazz</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" className='heatmap__sidebar--form--control'>
            <Select
              value={this.props.select.post}
              onChange={this.props.handleChange}
              displayEmpty
              name="post"
              className='heatmap__sidebar--form-items'
            >
              <MenuItem value={'all'}>All Posts</MenuItem>
              <MenuItem value={'post1'}>If so and so did something...</MenuItem>
              <MenuItem value={'post2'}>If so and so did something...</MenuItem>
              <MenuItem value={'post3'}>If so and so did something...</MenuItem>
              <MenuItem value={'post4'}>If so and so did something...</MenuItem>
            </Select>
          </FormControl>
        </form>
      </div>
    )
  }
}

export default HeatmapSidebar