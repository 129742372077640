import React, { Component } from "react"
import { geoMercator, geoPath } from "d3-geo"
import * as d3 from 'd3'
import { feature } from "topojson-client"
import axios from 'axios';

// COMPONENTS
import ChoroplethToolTip from '../../components/ChoroplethToolTip/ChoroplethToolTip'

// WORLD GEODATA
import worldGeoData from '../../data/d3_Maps_GeoData/world-110m.json'

// COUNTRY CONVERSION CHARTS
import countryCodeConversion from '../../data/d3_Maps_GeoData/codeConversions/reginal_Country_Codes_to_Names.json'


class WorldMap extends Component {
    state = {
      geoData: [],
      width: 1000,
      height: 600,
      isHovering: false,
      hoverLocation:'',
      hoverData:'',
      x: '',
      y: '',
      countryData: []
    }

  async componentDidMount() {
    // QUERY DATABASE
    // let countryData = await axios.get('/api/choropleth/world/date/all')
    let world = worldGeoData.objects.countries
    this.setState({
      // countryData: countryData.data,
      geoData: feature(worldGeoData, world).features
    })
  }

  projection = () => {
    return geoMercator()
    .scale(130)
    .translate([ this.state.width / 2, 400])
  }

  getCountryName = (countryId) => {
    let countryName = countryCodeConversion.filter(country => {
      return country.countryCode === countryId
    })
    return countryName[0] ? countryName[0].name : null
  }

  getCountryData = (countryName) => {
    let hoverData = this.props.data.filter(country => {
      return country.country === countryName
    })
    if (hoverData <= 0){ return 0
    } else { return hoverData[0].responsecount }
  }

  handleCountryHover = (countryId) => {
    let countryName =  this.getCountryName(countryId)
    let hoverData =  this.getCountryData(countryName)
    // console.log('hoverData', hoverData)
    this.setState({
      isHovering: true,
      hoverLocation: countryName,
      hoverData
    })
  }

  handleMouseMove = (event) => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    })
  }

  endHover = () => {
    this.setState({
      isHovering: false
    })
  }

  colorMap = (country) => {
    let countryName = this.getCountryName(country.id.toString())
    let color 
    let onlyValues = this.props.data.map(e => {
      return e.responsecount
    })
    let minValue = Math.min.apply(null, onlyValues),
      maxValue = Math.max.apply(null, onlyValues);

      // create color palette function
      // color can be whatever you wish
      let paletteScale = d3.scaleLog()
      .domain([minValue, maxValue])
      .range(["#EFEFFF", "#02386F"]); // blue color
            
      // fill dataset in appropriate format
      this.props.data.forEach(function (item) { //
        if(item.country === countryName){
          color = paletteScale(item.responsecount)
        }
      })
      return color ? color : '#EFEFFF'
    }
    
    render() {
      // console.log('dataset', this.colorMap())
    return (
      <div>
        { this.state.isHovering ? 
        <ChoroplethToolTip
          top={this.state.y -125}
          left={this.state.x -25}
          hoverLocation={this.state.hoverLocation}
          hoverData={this.state.hoverData} />
        : null }
        <svg 
          // width={this.state.width}
          // height={this.state.height}
          viewBox={`0 0 ${this.state.width} ${this.state.height}`}
        >
          <g className="countries">
            {
              this.state.geoData.map((d,i) => (
                <path
                  key={ `path-${ i }` }
                  d={ geoPath().projection(this.projection())(d) }
                  className="county"
                  fill={this.colorMap(d)}
                  stroke="#FFFFFF"
                  strokeWidth={ 0.5 }
                  onMouseMove={this.handleMouseMove}
                  onMouseLeave={() => this.endHover()}
                  onMouseOver={ () => this.handleCountryHover(d.id.toString())}
                  // cursor='pointer'
                />
              ))
            }
          </g>
        </svg>
      </div>
    )
  }
}

export default WorldMap