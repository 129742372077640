import React from 'react'

import {ResponsiveLine} from '@nivo/line'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BWWChart10 from '../../data/BWWChart10.json'
import WidgetHeaderHOC from '../../components/WidgetHeaderHOC/WidgetHeaderHOC.js';

const title ={
  title:'Game Driven Events By Time'
}

class AnalyticsChart10 extends React.Component {
  state = {
    chart: [],
    dataset: "Basketball",
    chartData: BWWChart10
  };

  handleChange = event => {
    this.setState({dataset: event.target.value});
  };

  render() {
    let datasetVale = this.state.dataset,
      chart = [];

    console.log("1",this.state);

    chart = this.state.chartData.filter(function(el) {
      return el.SportCategory === datasetVale;
    });

    // console.log("11",this.state);

    let keysArray = [],
      eventHash = {},
      chartArray = [];
    chart.forEach(function(i) {
      keysArray.push({x: i.Timestamp, y: i.Segment, ActivityType: i.ActivityType})
      eventHash[i.ActivityType] = ""
    });

    // console.log("2",eventHash, keysArray);

    Object.keys(eventHash).forEach(function(value, index) {
      let eventChart = keysArray.filter(function(el) {
        return el.ActivityType === value;
      });

      // eventChart.sort(function (a, b) {
      //     if (new Date(a.x).getTime() < new Date(b.x).getTime()) {
      //         return -1;
      //     }
      //     if (new Date(a.x).getTime() > new Date(b.x).getTime()) {
      //         return 1;
      //     }
      //     return 0;
      // });

      eventChart.sort(function (a, b) {
          if (a.y < b.y) {
              return 1;
          }
          if (a.y > b.y) {
              return -1;
          }
          return 0;
      });

      // console.log("3",value, index);
      chartArray.push({dataset: index, id: value, data: eventChart})
    });

    this.state.chart = chartArray;

    // console.log("4",this.state);

    return (<div className='vote-graph  primary-border' style={{
        width: '100%',
        paddingBottom: '30px'
      }}>
      <div className='vote-graph-top-bar'>
          <form style={{
            width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: '20px',
              marginRight: '20px'
            }} autoComplete="off">
            <FormControl style={{
                margin: 2,
                minWidth: 150
              }}>
              <Select value={this.state.dataset} onChange={this.handleChange} name="dataType">
                <MenuItem value={'Basketball'}>Basketball</MenuItem>
                <MenuItem value={'Football'}>Football</MenuItem>
                <MenuItem value={'Soccer'}>Soccer</MenuItem>
              </Select>
            </FormControl>
          </form>
      </div>
      <div className='chart' style={{
          padding: '10px'
        }}>
        <ResponsiveLine
          data={this.state.chart}
          isInteractive={true}
          curve='linear'
          lineWidth={0}
          margin={{
            "top" : 50,
            "right" : 20,
            "bottom" : 50,
            "left" : 30
          }}
          xScale={{
            'type': 'time',
            'format': "%d/%m/%Y %H:%M:%S %p",
            'precision': "minute"
           }}
           yScale={{
             'type': 'point'
           }}
           enableGridX={false}
           axisTop={null}
           axisRight={null}
           axisBottom={{
            'format': "%I:%M %p",
            // 'tickValues': 'every 15 minutes',
            'orient' : 'bottom',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 0,
            // 'legend' : 'Real Time',
            // 'legendOffset' : 56,
            // 'legendPosition' : 'middle'
          }}
          axisLeft={{
            'orient' : 'left',
            'tickSize' : 5,
            'tickPadding' : 5,
          }}
          colors={['var(--primary)','var(--opine-red)','var(--secondary)','#C6CFDB']}
          enablePoints={true}
          pointSize={12}
          pointBorderWidth={2}
          enableDots={true}
          enableDotLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[{
              "anchor": "top-right",
              "direction": "row",
              "justify": false,
              "translateX": 10,
              "translateY": -50,
              "itemsSpacing": 10,
              "itemDirection": "left-to-right",
              "itemWidth": 140,
              "itemHeight": 20,
              "itemOpacity": 0.75,
              "symbolSize": 15,
              "symbolShape": "circle",
              'symbolBorderColor': 'rgba(0, 0, 0, .5)',
              "effects": [
                {
                  "on": "hover",
                  "style": {
                    "itemBackground": "rgba(0, 0, 0, .03)",
                    "itemOpacity": 1
                  }
                }
              ]
            }
          ]}
          theme={{
            'grid' : {
              'line': {
                'stroke': '#EEF5F5',
                'strokeWidth': '1px'
              }
            },
            'axis' : {
              'ticks': {
                'text': {
                  'fill': 'var(--chart-axis-label)',
                  'fontSize': 18,
                  'fontWeight': '500',
                  'fontFamily': 'Source Sans Pro'
                }
              }
            }
          }}/>
      </div>
    </div>)
  }
}

const WrappedComponent = WidgetHeaderHOC(AnalyticsChart10, title)

export default WrappedComponent
