import React from 'react'

import BWWChart4Global from '../../../data/BWWChart4-Global.json'
import {ResponsiveSwarmPlot} from '@nivo/swarmplot'

class BWWAnalyticsChart4 extends React.Component {

  state = {
    dataProvider4: BWWChart4Global,
    chart4: [],
    annotationsArray4: []
  };

  handleChange = event => {
    this.setState({chart4: []});
    this.setState({annotationsArray4: []});
    // console.log(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  };


  render() {

    this.state.annotationsArray4 = [];
    this.state.chart4 = [];

    let that = this;

    this.state.chart4 = this.state.dataProvider4.filter(function(el) {
      return (el.OfferType === that.props.selectedOffer1 || el.OfferType === that.props.selectedOffer2) && (el.EventType === that.props.selectedEvent1 || el.EventType === that.props.selectedEvent2) && (el.Huddle === that.props.selectedHuddle);
    });

    this.state.chart4.forEach(function(i, indi) {
      if (i.hasOwnProperty("OfferType")) {
        i.group = i.OfferType;
      }
      if (i.hasOwnProperty("EventType")) {
        i.id = i.EventType+" "+indi;
      }
      if (i.EventType === that.props.selectedEvent2) {
        that.state.annotationsArray4.push({
          type: 'circle',
          match: {
            index: indi
          },
          noteX: 40,
          noteY: 40,
          offset: 4,
          note: that.props.selectedEvent2
        })
      }
      if (i.EventType === that.props.selectedEvent1) {
        that.state.annotationsArray4.push({
          type: 'rect',
          match: {
            index: indi
          },
          noteX: -40,
          noteY: -40,
          offset: 4,
          note: that.props.selectedEvent1
        })
      }
    });

    // console.log(this.props)
    // console.log(this.state)

    return (

      <div style={{paddingTop: "80px"}}>

	    <div className='row' style={{
	        height: '400px'
	      }}>
	      <ResponsiveSwarmPlot
          data={this.state.chart4}
					groups={[this.props.selectedOffer1, this.props.selectedOffer2]}
					value="Value"
          valueScale={{
            type: 'linear',
            min: 30,
            max: 100
	        }}
          identity="id"
					annotations={this.state.annotationsArray4}
					spacing={20}
	        // size={{ key: 'Value', values: [ 4, 20 ], sizes: [ 6, 20 ] }}
	        size={12}
          colorBy="id"
          borderColor={{
	          from: [
	            '#D2AA41', '#00B1FF', '#F4EDD8', '#00B1FF'
	          ],
	          modifiers: [
	            [
	              'darker', 0.6
	            ],
	            [
	              'opacity', 0.4
	            ]
	          ]
	        }}
          margin={{
	          top: 80,
	          right: 100,
	          bottom: 80,
	          left: 100
	        }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
	          orient: 'bottom',
	          tickSize: 10,
	          tickPadding: 4,
	          tickRotation: 0,
	          //legend: 'Offers',
	          legendPosition: 'middle',
	          legendOffset: 46
	        }}
          axisLeft={{
	          orient: 'left',
	          tickSize: 10,
	          tickPadding: 4,
	          tickRotation: 0,
	          legend: 'Avg $ Per Order',
	          legendPosition: 'middle',
	          legendOffset: -76
	        }}
          animate={false}
          theme={{
	          'grid' : {
	            'line': {
	              'stroke': '#EEF4F4',
	              'strokeWidth': '1px'
	            }
	          },
	          'axis' : {
	            'ticks': {
	              'text': {
	                'fill': '#BDD4D7',
	                'fontSize': 18,
	                'fontWeight': '400',
	                'fontFamily': 'Source Sans Pro'
	              }
	            }
	          },
	          'tooltipFormat' : {
	            'container': {
	              'background': 'red'
	            }
	          }
	        }}/>
	    </div>
      </div>

	)
  }
}

export default BWWAnalyticsChart4
