import React from 'react'
import './FullSizeChoropleth.css'
import axios from 'axios'

// MATERIAL UI
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';


// COMPONENTS
import HeatmapSidebar from '../../components/HeatMapSidebar/HeatmapSidebar';
import WorldMap from '../../charts/D3Map/D3_World_Map'
import UsMap from '../../charts/D3Map/D3_States_Map'
import RankingTable from '../../components/RankingTable/RankingTable';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Firebase from '../../components/Firebase'
const firebase = new Firebase()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#002A5C'
    },
    secondary: {
      main: '#1FFF90'
    }
  }
});

class FullSizeChoropleth extends React.Component{
  state= {
    mapSelect: 'world',
    date: 0,
    event: 'all',
    feed: 'all',
    post: 'all',
    data: [],

  }

  async componentDidMount(){
    let getData =  firebase.functions.httpsCallable('dashboardDateInterval')
    let data = await getData({interval:0})
    // let data = await axios.post(`/api/choropleth/world/date`, {interval: 0})
    this.setState({
      data: data.data
    })
  }

  getFilterDropdowns = async () => {
    let items = await axios.post('/api/choropleth/world/filteredEvents')
    // console.log('items', items)
  }

  getData = async (name) => {
    if (this.state.mapSelect === 'us'){

    } else {
      let getData =  firebase.functions.httpsCallable('dashboardDateInterval')
      let data = await getData({interval:this.state.date})
      // let data = await axios.post(`/api/choropleth/world/${name}`, {
      //   interval:this.state.date,
      //   event:this.state.event,
      //   feed:this.state.feed,
      //   post:this.state.post
      // })
      console.log('thisone', data)
      this.setState({
        data: data.data
      })
    }
  }

  handleChange = async event => {
    await this.setState({ 
      [event.target.name]: event.target.value,
    });
    this.getData(event.target.name)
  };

  handleClick = map => {
    this.setState({
      mapSelect: map
    })
  }

  scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth' });
  }
  
  render(){
    // console.log('state',this.state.data)
    return(
      <MuiThemeProvider theme={theme}>
      <div className='full-size-choropleth'>
        <HeatmapSidebar 
          handleClick={this.handleClick} 
          handleChange={this.handleChange}
          select={this.state}
        />
        <div className='full__size__choropleth--middle'>
          <h1>Select a Region</h1>
          <p id='heatmap--subhead'>See your data across the world, or dig into your US engagement</p>
            {this.state.mapSelect === 'us' ? 
          <div className='heatmap--buttons'>
            <Button variant='outlined' color='primary'  id='heatmap--button' onClick={e => this.handleClick('us')}>US</Button>
            <Button variant='contained' color='primary' id='heatmap--button' onClick={e => this.handleClick('world')}>World</Button> 
          </div>
            :
          <div className='heatmap--buttons'>
            <Button variant='contained' color='primary' id='heatmap--button' onClick={e => this.handleClick('us')}>US</Button>
            <Button variant='outlined' color='primary'  id='heatmap--button' onClick={e => this.handleClick('world')}>World</Button> 
          </div>
            }
          <div className='heatmap-container'>
            <div style={{width:'100%'}}>
              {this.state.mapSelect === 'world' ? <WorldMap data={this.state.data}/> : <UsMap />}
            </div>
          </div>
          <div style={{width:'90%', margin:'0 auto', marginBottom:'30px'}} ref={el => { this.el = el; }}>
            <RankingTable map={this.state.mapSelect} date={this.state.date}/>
          </div>
        </div>
      </div>
      <div id='fab__container'>
        <Button id='fab' variant='fab' color='primary' onClick={e => this.scrollToBottom()}>
          <ExpandMore/>
        </Button>
      </div>
      </MuiThemeProvider>
    )
  }
}


export default FullSizeChoropleth