import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import CardHOC from '../../cards/CardHOC/CardHOC'
import AnalyticsChart04Data01 from '../../data/AnalyticsChart04_DataSet01.json'
import AnalyticsChart04Data02 from '../../data/AnalyticsChart04_DataSet02.json'
import AnalyticsChart04Data03 from '../../data/AnalyticsChart04_DataSet03.json'
import AnalyticsChart04Data04 from '../../data/AnalyticsChart04_DataSet04.json'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import numeral from 'numeral'

 
// const barChartData = 

const data = {
  title: 'Responses per Huddle',
  description: 'This chart shows the total number of responses by a huddle to all feeds over a period of time.',
  url: 'Opinelive.com',
}

class AnalyticsChart2 extends React.Component{
  state={
    timeFrame: AnalyticsChart04Data01,
    filter: 'Time'
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    let responseArr = []
    this.state.timeFrame.map(e => {
      return responseArr.push(e.value)
    })
    let responseSum = responseArr.reduce(function(accumulator, currentValue, currentIndex, array) {
      return accumulator + currentValue;
    });
    // console.log('timeFrame',this.state.timeFrame)
    // console.log('response',responseArr)
    // console.log('responseSum',responseSum)
    return(
      <div className='card-HOC-chart-container'>
          <form style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', marginBottom:'20px', marginRight:'20px'}} autoComplete="off">
            <FormControl style={{margin: 2, minWidth: 150, }}>
              <Select
                value={this.state.timeFrame}
                onChange={this.handleChange}
                displayEmpty
                name="timeFrame"
                className='{classes.selectEmpty}'
              >
                <MenuItem value={AnalyticsChart04Data01}>This Week</MenuItem>
                <MenuItem value={AnalyticsChart04Data02}>Last Week</MenuItem>
                <MenuItem value={AnalyticsChart04Data03}>Last Month</MenuItem>
                <MenuItem value={AnalyticsChart04Data04}>All Time</MenuItem>
              </Select>
            </FormControl>
          </form>
          <ResponsivePie
        data={this.state.timeFrame}
        margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
        innerRadius={0.75}
        padAngle={1}
        cornerRadius={3}
        colors={['#002a5c','#33557d','#4d6a8d','#667f9d','#8095ae','#99aabe']}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#fff"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
      <div style={{position:'relative', bottom:'220px', display:'flex', flexDirection: 'column', alignItems:'center'}}>
        <p id='engagement-count'>
          {responseSum> 1000 ? numeral(responseSum).format('0.0a') : responseSum}
        </p>
        <p id='vote-count-label'>Total responses</p>
      </div>
      </div>
    )
  }
}
const WrappedComponent = CardHOC(AnalyticsChart2, data)

export default WrappedComponent