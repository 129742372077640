export default {
  FIREBASE: {
    VERSION: 'v1-prod',
  },
  LOCAL_STORAGE: {
    SENTIMENT_BY_ID_PREFIX: 'sentiment-by-id-',
    PROMPTS_BY_ID_PREFIX: 'prompts-by-id-',
  },
  CHART: {
    RESOLUTION: 2,
    X_AXIS_MILLIS: 1000 * 60 * 7,
    COLORS: {
      AXES: '#7D8995',
      PRIMARY: '#002A5C',
      BACKGROUND: '#FFFFFF',
    },
    DIMENSIONS: {
      X_AXIS_INSET: 50,
      Y_AXIS_INSET: 100,
      X_AXIS_LINE_WIDTH: 1,
      Y_AXIS_LINE_WIDTH: 0.5,
      X_AXIS_TIME_SERIES_INSET: 75,
      Y_AXIS_TIME_SERIES_INSET: 25,
      X_TIME_SERIES_LINE_WIDTH: 6,
    },
  },
};
