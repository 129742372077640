import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading() {
  return(
    <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <CircularProgress />
      <h1>Loading...</h1>
    </div>
  )
}