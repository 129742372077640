import React from 'react'

import './RecommendedEvents.scss'

import photo from '../../assets/images/cowboys_slider_image.jpg'

class RecommendedEvents extends React.Component{
  state = {

  }

  render(){
    let {event} = this.props
    return(
      <div className='recommended__event'>
        <img src={photo} alt='' className='recommended__event-img'/>
        <div className='recommended__event-event__info'>
          <p id='event__info-league'>{event.league}</p>
          <p id='event__info-event'>{event.eventName}</p>
        </div>
      </div>
    )
  }
}

export default RecommendedEvents