import React from 'react';
import EkgChartWithData from '../../charts/EkgChart/EkgChartWithData';
import EkgSentimentContext from '../../charts/EkgChart/ekg-sentiment-context';
import EkgPromptsContext from '../../charts/EkgChart/ekg-prompts-context';
import './Dashboard.css';
import PropTypes from 'prop-types';
import cowboysLogo from '../../assets/images/Dallas_Cowboys.png';
import { AuthUserContext, withAuthorization } from '../../components/Session';

class EventAnalysis extends React.Component {
  state = {
    logo: cowboysLogo,
  };

  playVideoClip = (vidUrl) => {
    if (vidUrl && document.getElementById('snippetPlayer'))
    {
      document.getElementById('snippetPlayer').src = vidUrl;
    }
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="dashboard">
            <div className="row">
              <EkgSentimentContext>
                <EkgPromptsContext>
                  <EkgChartWithData eventId="ns2mhwqvb6" xAxisMinutes="35" xAxisIncrement="5" reverseXAxis="0" />
                </EkgPromptsContext>
              </EkgSentimentContext>
            </div>
            <div className="row">
              <div style={{float:"left", backgroundColor: "#FFFFFF", width: "100%", borderTop: "5px dashed #EBEBEB", borderBottom: "5px dashed #EBEBEB"}}>
                <img onClick={(e) => this.playVideoClip("https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2Fns2mhwqvb6_01.mp4?alt=media&token=66180ae1-9fc2-47bf-96e7-7350eba77355", e)} style={{paddingLeft: "70px", paddingTop: "20px", paddingBottom: "20px", cursor: "pointer"}} src="https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2FScreenCap01.PNG?alt=media&token=54e65e32-9ee0-4fd1-977c-e3dbc196c631" width="120" />
                <img onClick={(e) => this.playVideoClip("https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2Fns2mhwqvb6_02.mp4?alt=media&token=9c14932c-8d95-4619-8a80-edc920ce1a92", e)} style={{paddingLeft: "80px", paddingTop: "20px", paddingBottom: "20px", cursor: "pointer"}} src="https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2FScreenCap02.PNG?alt=media&token=9678da98-3e3d-4ff5-80d2-288ee0e3c04f" width="120" />
                <img onClick={(e) => this.playVideoClip("https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2Fns2mhwqvb6_03.mp4?alt=media&token=cf001552-e45f-4948-8e02-68586bccb940", e)} style={{paddingLeft: "35px", paddingTop: "20px", paddingBottom: "20px", cursor: "pointer"}} src="https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2FScreenCap03.PNG?alt=media&token=d393628b-19a8-4f12-b069-c46f35d956fa" width="120" />
                <img onClick={(e) => this.playVideoClip("https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2Fns2mhwqvb6_04.mp4?alt=media&token=909369bf-3d8b-4168-9ac3-60de5bc75f4c", e)} style={{paddingLeft: "20px", paddingTop: "20px", paddingBottom: "20px", cursor: "pointer"}} src="https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2FScreenCap04.PNG?alt=media&token=e365de94-9747-43f7-bd7e-0bfef0c40cb3" width="120" />
                <img onClick={(e) => this.playVideoClip("https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2Fns2mhwqvb6_05.mp4?alt=media&token=9c567ae7-ea23-47db-b7be-fd831c3d6661", e)} style={{paddingLeft: "100px", paddingTop: "20px", paddingBottom: "20px", cursor: "pointer"}} src="https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2FScreenCap05.PNG?alt=media&token=cbdaddba-f4fd-40a4-8996-9fea6451ee4f" width="120" />
                <img onClick={(e) => this.playVideoClip("https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2Fns2mhwqvb6_06.mp4?alt=media&token=ba33042d-cec1-4b72-8864-cd4975627c4f", e)} style={{paddingLeft: "50px", paddingTop: "20px", paddingBottom: "20px", cursor: "pointer"}} src="https://firebasestorage.googleapis.com/v0/b/opine-188b9.appspot.com/o/v1%2Fvideo_clips%2FScreenCap06.PNG?alt=media&token=860e46a1-c8af-4e90-9c30-85f534ef129a" width="120" />
              </div>
            </div>
            <div className="row">
              <iframe id="snippetPlayer" width="720" height="480" src="" frameborder="1" allowfullscreen></iframe>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}


EventAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(EventAnalysis);
