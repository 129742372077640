export default (data, xAxisMillis) => {
  const timeSeries = data
    .map(item => ({ ...item, timestamp: new Date(item.timestamp).getTime() }))
    .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));
  const [first, [last]] = [timeSeries[0], timeSeries.slice(-1)];
  const minEndMillis = last.timestamp + xAxisMillis;
  const minStartMillis = first.timestamp - xAxisMillis;
  const rangeStart = Math.max(minStartMillis, last.timestamp);
  const rangeEnd = Math.max(minEndMillis, first.timestamp);

  return { timeSeries, range: [rangeStart, rangeEnd] };
};
