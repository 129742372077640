import React from 'react'

import { ResponsiveLine } from '@nivo/line'
import BWWChart11 from '../../data/BWWChart11.json'
import WidgetHeaderHOC from '../../components/WidgetHeaderHOC/WidgetHeaderHOC.js';

const title ={
  title:'In-Game Acvtivity & Orders vs Intensity'
}

class AnalyticsChart11 extends React.Component{
  state={
    chart: BWWChart11,
    name: 'Global',
    chartData: [],
    customBulletData: [],
    customBulletDataOrder: []
  };

  render(){
    let keysArray = [], InGameActivityYNArray = [], OrderYNArray = [];
    let convertDate = function(dt){
      let tmp = new Date(dt)
      tmp = ((tmp.getHours()<10?'0':'') + tmp.getHours()) + '' + ((tmp.getMinutes()<10?'0':'') + tmp.getMinutes())
      return parseInt(tmp)
    };
    this.state.chart.forEach(function(i) {
      keysArray.push({x: i.Timestamp, y: i.AvgSentiment, InGameActivityYN: i.InGameActivityYN, OrderYN: i.OrderYN})
      if(i.InGameActivityYN === true){InGameActivityYNArray.push(convertDate(i.Timestamp))}
      if(i.OrderYN === true){OrderYNArray.push(convertDate(i.Timestamp))}
    });

    // console.log('InGameActivityYNArray',InGameActivityYNArray);
    // console.log('********************',[convertDate(keysArray[0].x), convertDate(keysArray[keysArray.length-1].x)]);

    this.state.chartData = [{
      dataset: '1',
      id: '',
      data: keysArray.slice(0, 100)
    }]


    let width = 100 / (convertDate(keysArray[keysArray.length-1].x) - convertDate(keysArray[0].x));
    let divElem = [], divElemOrder = [];
    for(let i = convertDate(keysArray[0].x); i < convertDate(keysArray[keysArray.length-1].x); i++){
      if(InGameActivityYNArray.indexOf(i) > -1){
        divElem.push(<div key={i} style={{width: ""+width+"%",height: "30px",backgroundColor: "var(--secondary)", display: "inline-block"}}></div>)
      }
      else{
        divElem.push(<div key={i} style={{width: ""+width+"%",height: "30px", display: "inline-block"}}></div>)
      }

      if(OrderYNArray.indexOf(i) > -1){
        divElemOrder.push(<div key={i} style={{width: ""+width+"%",height: "30px",backgroundColor: "var(--primary)", display: "inline-block"}}></div>)
      }
      else{
        divElemOrder.push(<div key={i} style={{width: ""+width+"%",height: "30px", display: "inline-block"}}></div>)
      }

    }

    this.state.customBulletData = divElem
    this.state.customBulletDataOrder = divElemOrder

    // console.log(this.state);
    // console.log(divElem);


    return (
      <div className='vote-graph  primary-border' style={{
        width: '100%',
        paddingBottom: '30px'
      }}>
      <div className='vote-graph-top-bar'>
      </div>
      <div className='chart' style={{
          padding: '10px'
        }}>
        <ResponsiveLine
          data={this.state.chartData}
          curve='linear'
          margin={{
            'right' : 20,
            'bottom' : 50,
            'left' : 50,
            'top': 5
          }}
          xScale={{
            'type': 'time',
            'format': '%d/%m/%Y %H:%M:%S %p',
            'precision': 'minute'
           }}
           yScale={{ type: 'linear', min: '0', max: '100' }}
           xFormat='time:%H:%M:%S %p'
           enableGridX={false}
           axisTop={null}
           axisRight={null}
           axisBottom={{
            'format': '%I:%M %p',
            'tickValues': 'every 5 minutes',
            'orient' : 'bottom',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 0,
            'legend' : 'Real Time',
            'legendOffset' : 56,
            'legendPosition' : 'middle'
          }}
          axisLeft={{
            // 'tickValues': [10, 25, 50, 80],
            'orient' : 'left',
            'tickSize' : 5,
            'tickPadding' : 5,
            'tickRotation' : 0,
            // 'legend' : 'Event Time',
            // 'legendOffset' : -40,
            // 'legendPosition' : 'middle'
          }}
          colors={'var(--primary)'}
          enableDots={false}
          enableDotLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={{
            'grid' : {
              'line': {
                'stroke': '#EEF5F5',
                'strokeWidth': '1px'
              }
            },
            'axis' : {
              'ticks': {
                'text': {
                  'fill': 'var(--chart-axis-label)',
                  'fontSize': 18,
                  'fontWeight': '500',
                  'fontFamily': 'Source Sans Pro'
                }
              }
            },
            'tooltipFormat' : {
              'container': {
                'background': 'red'
              }
            }
          }}/>
      </div>

      <h3 style={{marginTop: "0px"}} className='highlight-primary'>In Game Activity Indicator</h3>
      <div style={{
          width: "100%",
          height: "30px",
          marginBottom: "10px"}}>
          {this.state.customBulletData}
      </div>


      <h3 className='highlight-primary'>Order Timeline</h3>
      <div style={{
          width: "100%",
          height: "30px",
          marginBottom: "0px"}}>
          {this.state.customBulletDataOrder}
      </div>

    </div>)
  }
}

const WrappedComponent = WidgetHeaderHOC(AnalyticsChart11, title)

export default WrappedComponent
