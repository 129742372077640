import React from 'react'
import { Line } from '@nivo/line'
import CardHOC from '../../cards/CardHOC/CardHOC'

import range from 'lodash/range';
import * as time from 'd3-time';
import { timeFormat } from 'd3-time-format';
import last from 'lodash/last';
import { generateDrinkStats } from '@nivo/generators';

// const barChartData =  
const chartData = {
  title: 'Real Time Publisher Engagement vs Opine Trends',
  description: 'This chart compares published statements and responses across all the Opine publisher community.',
  url: 'Opinelive.com',
}
const data = generateDrinkStats(18);
const commonProperties = {
  width: 450,
  height: 400,
  data,
  animate: true,
  enableSlices: 'x',
};
const date = new Date();
date.setMinutes(0);
date.setSeconds(0);
date.setMilliseconds(0);

class RealTimeChart extends React.Component {
  state = {
    dataA: range(100).map(i => ({
      x: time.timeMinute.offset(date, i * 30),
      y: 10 + Math.round(Math.random() * 20),
    })),
    dataB: range(100).map(i => ({
      x: time.timeMinute.offset(date, i * 30),
      y: 30 + Math.round(Math.random() * 20),
    })),
    dataC: range(100).map(i => ({
      x: time.timeMinute.offset(date, i * 30),
      y: 50 + Math.round(Math.random() * 20),
    })),
    dataD: range(100).map(i => ({
      x: time.timeMinute.offset(date, i * 30),
      y: 70 + Math.round(Math.random() * 20),
    })),
  };
  formatTime = timeFormat('%Y %b %d');

  componentDidMount() {
    this.timer = setInterval(this.next, 100);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  next = () => {
    const dataA = this.state.dataA.slice(1);
    dataA.push({
      x: time.timeMinute.offset(last(dataA).x, 30),
      y: 10 + Math.round(Math.random() * 20),
    });
    const dataB = this.state.dataB.slice(1);
    dataB.push({
      x: time.timeMinute.offset(last(dataB).x, 30),
      y: 30 + Math.round(Math.random() * 20),
    });
    const dataC = this.state.dataC.slice(1);
    dataC.push({
      x: time.timeMinute.offset(last(dataC).x, 30),
      y: 50 + Math.round(Math.random() * 20),
    });
    const dataD = this.state.dataD.slice(1);
    dataD.push({
      x: time.timeMinute.offset(last(dataD).x, 30),
      y: 70 + Math.round(Math.random() * 20),
    });

    this.setState({ dataA, dataB, dataC, dataD });
  };

  render() {
    const { dataA, dataB, dataC, dataD } = this.state;
    return (
      <div className='card-HOC-chart-container'>
        <Line
          {...commonProperties}
          margin={{ top: 30, right: 0, bottom: 100, left: 40 }}
          data={[{ id: 'Publisher Prompts', data: dataA }, { id: 'Opine Prompts', data: dataB }, { id: 'Publisher Responses', data: dataC }, { id: 'Opine Responses', data: dataD }]}
          xScale={{ type: 'time', format: 'native' }}
          yScale={{ type: 'linear', max: 100 }}
          colors={['#0070AC','#C6CFDB','#D2AA41','#F5EDD8']}
          axisTop={null}
          // {{
          //   format: '%H:%M',
          //   tickValues: 'every 4 hours',
          // }}
          axisBottom={null}
          // {{
          //   format: '%H:%M',
          //   tickValues: 'every 4 hours',
          //   legend: `${this.formatTime(dataA[0].x)} ——— ${this.formatTime(last(dataA).x)}`,
          //   legendPosition: 'middle',
          //   legendOffset: 46,
          // }}
          axisRight={null}
          enablePoints={false}
          enableGridX={true}
          curve="monotoneX"
          animate={false}
          motionStiffness={120}
          motionDamping={50}
          isInteractive={false}
          enableSlices={false}
          useMesh={true}
          theme={{
            axis: { ticks: { text: { fontSize: 14 } } },
            grid: { line: { stroke: '#ddd', strokeDasharray: '1 2' } },
          }}
          legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: -20,
                translateY: 100,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 110,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        />
      </div>
    );
  }
}

const WrappedComponent = CardHOC(RealTimeChart, chartData)

export default WrappedComponent