import React, { useCallback, useState } from 'react';
import Firebase from '../../components/Firebase';
import constants from '../../constants';

const firebase = new Firebase();

export const EkgPromptsContext = React.createContext();

export default ({ children }) => {
  const [promptsByEventId, setPromptsByEventId] = useState({});
  const addPrompts = useCallback(
    (eventId, newRecords = []) => {
      const cachedRecords = getPromptsFromLocalStorageByEventId(eventId);
      const newestId = newRecords.length && newRecords[0].__id;
      const lastCachedId = cachedRecords.length && cachedRecords[0].__id;

      if (newestId !== lastCachedId) {
        const records = [...newRecords, ...cachedRecords];

        savePromptsFromLocalStorageByEventId(eventId, records);

        setPromptsByEventId(promptsByEventId => ({
          ...promptsByEventId,
          [eventId]: records,
        }));
      }
    },
    [setPromptsByEventId]
  );
  const getHandleSnapshot = useCallback(
    eventId => snapshot => {
      const newRecords = firebase.flattenSnapshot(snapshot);

      addPrompts(eventId, newRecords);
    },
    [addPrompts]
  );
  const listen = useCallback(
    async eventId => {
      const query = getQuery(eventId);
      const snapshot = await query.get();
      const handleSnapshot = getHandleSnapshot(eventId);

      handleSnapshot(snapshot);

      const unlisten = query.limit(1).onSnapshot(handleSnapshot);

      return unlisten;
    },
    [addPrompts]
  );

  return (
    <EkgPromptsContext.Provider value={{ listen, promptsByEventId }}>
      {children}
    </EkgPromptsContext.Provider>
  );
};

function getQuery(eventId) {
  const cachedRecords = getPromptsFromLocalStorageByEventId(eventId);
  const mostRecentCachedRecord = cachedRecords[0];
  const promptsRef = firebase.getPromptsRefByEventId(eventId);
  const unorderedQuery = mostRecentCachedRecord
    ? promptsRef.where('timestamp', '>', mostRecentCachedRecord.timestamp)
    : promptsRef;

  return unorderedQuery.orderBy('timestamp', 'desc');
}

function getPromptsFromLocalStorageByEventId(eventId) {
  const key = getKey(eventId);
  const promptsString = localStorage.getItem(key);
  const records = JSON.parse(promptsString || '[]');

  return records.map(record => ({
    ...record,
    timestamp: firebase.createTimestamp(record.timestamp),
  }));
}

function savePromptsFromLocalStorageByEventId(eventId, records) {
  const promptsString = JSON.stringify(records);
  const key = getKey(eventId);

  localStorage.setItem(key, promptsString);
}

function getKey(eventId) {
  return constants.LOCAL_STORAGE.PROMPTS_BY_ID_PREFIX + eventId;
}
