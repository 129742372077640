import React, { useEffect, useState } from 'react'
import './OverviewWidget.css'
import numeral from 'numeral'
import Avatar from '@material-ui/core/Avatar'
import Firebase from '../../components/Firebase'
import Loading from '../../components/Loading/Loading'

const firebase = new Firebase()

export default function OverviewWidget(props) {

	const [data, setData] = useState({publisherid: null, usercount: null, promptcount: null, responsecount: null, publisher:'Dallas Cowboys'})
	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState(null)

	useEffect(() => {
		// FETCH DATA
		let fetchData = async () => {
			setIsLoading(true);
			let getTopFeeds =  firebase.functions.httpsCallable('dashboardGetFollowerData')
			let results = await getTopFeeds()
			// SET STATE
			setData(results.data[0])
			setName('Dallas Cowboys')
			setIsLoading(false);
		}
		fetchData()
	},[])

	data.publisher = props.data.name || name

	return (
		<div className='third' style={{minHeight:'540px'}}>
		{isLoading ?
			<Loading />
			:
			<div className='overview-widget'>
				<div id='publisher-bg-img'/>
			<Avatar src={props.data.logo} alt='Publisher Name' id='publisher-img'/>
			<h1 id='dashboard-title'>{data.publisher}</h1>

			<div className='column'>
				<p id='engagement-count'>
					{data.usercount > 1000 ? numeral(data.usercount).format('0.0a') : data.usercount}
				</p>
				<h3 id='vote-count-label'>Total Followers</h3>
			</div>

			<div className='column'>
				<p id='response-count'>
					{data.responsecount > 1000 ? numeral(data.responsecount).format('0.0a') : data.responsecount}
				</p>
				<h3 id='response-count-label'>Total Votes</h3>
			</div> 
			<p className='disclaimer' id='publisher-disclaimer'>This includes posts from all feeds connected to your account whether disabled or not.</p>
		</div>
		}
	</div>
	)
}